import {useState, useEffect} from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Typography} from '@mui/material';
import IOTable from './ioTable';
import CircularProgress from '@mui/material/CircularProgress';

var addressSections = {}

const CSSoverride = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function IOSections({deviceName}) {

    const [sectionFetchComplete, setSectionFetcComplete] = useState(false);
    

    useEffect(() => {
      
        fetch("/api/inventory/register-sections?device_name="+deviceName)
        .then(resp =>{
          if(resp.status === 200) return resp.json(); 
        })
        .then(data => {
            addressSections = data
            setSectionFetcComplete(true);
        })
        .catch((err) => {
          console.log(err)
        });
      
    }, [])
   
    return(
        <div style ={{marginLeft:"30px", marginRight:"30px", marginBottom:"30px"}}>

        {sectionFetchComplete?(
          Object.keys(addressSections).map((key, index) => {
            return (
              <div key={index}>
                <Accordion TransitionProps={{ mountOnEnter: true , timeout:900}} sx={{
                      background: "linear-gradient(to bottom, #005DAA, #353839)"
                    }}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      background: "linear-gradient(to right, #FFFFFF, #FFFFFF)",
                      margin:"10px"
                    }}
                    >
                    <Typography sx={{fontSize:18}}>{key}</Typography>
                    </AccordionSummary>

                    
                    <IOTable
                          device={deviceName}
                          registerAddrList={addressSections[key]}
                          sectionFetchComplete={sectionFetchComplete}
                      />
                      
                </Accordion>
                
              </div>
              

            );
            
          })):(<div><br/><br/><CircularProgress/></div>)
        }
        </div>
    )
}