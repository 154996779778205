//Widget components:
import Speedometer from "./widgets/speedometer";
import DashboardChart from "./widgets/chart";
import InstantFetchTable from "./widgets/instantFetch";
import StatusRibbon from "./widgets/statusRibbon";
import RegSelect from "./widgets/chartSelect";
import Timeline from "./widgets/timeline";
import Thermalmeter from "./widgets/thermometer";
import FillBar from "./widgets/fillBar";
import IOSections from "./widgets/ioSections";
import PumpCurve from "./widgets/pumpCurve";

//options:
import { defaultOption, liveOption } from "./widgets/options/chartOptions";
import "../css/chart.css";

//Libs:
import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Select from "react-select";
import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Scrollbars } from "react-custom-scrollbars";
import CssBaseline from "@mui/material/CssBaseline";
import IrisInput from "./widgets/irisInput";
import FloatSensor from "./widgets/floatSensor";
import table_img from "../assets/table_image.png";
import { de } from "date-fns/locale";
import TextWidget from "./widgets/textWidget";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#4CAF50",
    "&:hover": {
      backgroundColor: alpha("#4CAF50", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#1ED760",
  },
}));

//Arrays to store react-select options
var registerOptions = [];
var registerInputStaticLeft = [];
var registerInputStaticRight = [];
var registerInput2 = [];
var registerInput3 = [];

//A bit of a hack but can be done in one line by taking advantage of the fact that Sweden uses a format very close to ISO:
function dateToISOButLocal(date) {
  return date.toLocaleString("sv").replace(" ", "T") + "Z";
}

export default function WidgetPage({ deviceName, renderDefaults }) {
  const [registersChartStaticLeft, setRegistersChartStaticLeft] = useState(
    renderDefaults.chart
  );
  const [registersChartStaticRight, setRegistersChartStaticRight] = useState(
    renderDefaults.chart
  );
  const [registersChart2, setRegistersChart2] = useState(renderDefaults.chart);
  const [registersTable, setRegistersTable] = useState(renderDefaults.table);
  const [regDropOpts, setRegDropOpts] = useState(registerOptions);

  useEffect(() => {
    setRegistersTable(renderDefaults.table);
    setRegistersChartStaticLeft(renderDefaults.chart);
    setRegistersChartStaticRight(renderDefaults.chart);
    setRegistersChart2(renderDefaults.chart);
    //console.log(renderDefaults)
  }, [, renderDefaults]);

  //Setting start and stop dates:

  //5 day offset for start time
  var dateOffset = 24 * 60 * 60 * 1000 * 5; //5days
  var startDate = new Date();
  startDate.setTime(startDate.getTime() - dateOffset);

  var stopDate = new Date();
  stopDate.setTime(stopDate.getTime() + dateOffset / 5);

  //Date (local-timezone-aware) sent with requests. Does not work with MUI.
  const [startTime1, setStartTime1] = useState(dateToISOButLocal(startDate));
  const [stopTime1, setStopTime1] = useState(
    dateToISOButLocal(dateToISOButLocal(stopDate)) //Adding a day to the stop time since telemetry data is still stored and charted in UTC.
  );

  //Date (UTC) the user sees. Need to maintain separate instances of the date since MUI LocalizationProvider requires UTC input.
  const [startTime1UTC, setStartTime1UTC] = useState(startDate.toISOString());
  const [stopTime1UTC, setStopTime1UTC] = useState(stopDate.toISOString());

  const [startTime2, setStartTime2] = useState(dateToISOButLocal(startDate));
  const [stopTime2, setStopTime2] = useState(dateToISOButLocal(new Date()));

  const [live, setLive] = useState(false);

  //used to autoscroll to chart on wdget-click
  const chartRef = useRef();

  const [totalDynamicHead, setTotalDynamicHead] = useState(250);
  const [capacity, setCapacity] = useState(600);

  const handleLiveSwitch = (event) => {
    setLive(event.target.checked);
  };
  var url = window.location.pathname.split("/").pop();
  // Supporting functions for register selection
  useEffect(() => {
    registerOptions = [];
    setRegDropOpts(registerOptions);
    fetch("/api/inventory/registerlist?device_name=" + deviceName) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            registerOptions.push({ label: data[key], value: key });
            setRegDropOpts(registerOptions);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [deviceName]);

  //handle user-input
  function handleWidgetClick(register) {
    setRegistersChartStaticLeft([register + ""]);
    setRegistersChart2([register + ""]);
    chartRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const handleChange = (obj, chart_id) => {
    if (chart_id == 1) {
      registerInputStaticLeft = obj;
      var processedRegisterInput = obj.map((a) => a.value);
      setRegistersChartStaticLeft(processedRegisterInput);
    }

    if (chart_id == 2) {
      registerInput2 = obj;
      var processedRegisterInput = obj.map((a) => a.value);
      setRegistersChart2(processedRegisterInput);
    }

    if (chart_id == 3) {
      registerInput3 = obj;
      var processedRegisterInput = obj.map((a) => a.value);
      setRegistersTable(processedRegisterInput);
    }
  };

  //styles for selected options
  const styles = {
    width: 100,
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "lightgray",
      };
    },
  };

  // Inline-Styles for divs
  // Banner styles
  const mainBanner = {
    width: "99%",
    minWidth: "99%",
    marginLeft: "10px",
    marginRight: "10px",
    height: "40px",
    background: "#224663",
    border: "0.1px solid #1ED760",
    borderRadius: "15px",
    marginTop: "3px",
    textAlign: "center",
    lineHeight: "2.5",
    color: "white",
    fontSize: "16px",
  };

  const subBanner = {
    border: "0.3px solid white",
    borderRadius: "15px",
    color: "white",
    fontSize: "16px",
    margin: "3px",
  };

  // Widget div styles
  const dialDiv = {
    cursor: "pointer",
    marginTop: "2.5%",
    width: "20%",
    minWidth: "250px",
    height: "40%",
    background: "none",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    // marginTop: '10px',
    // marginBottom: '10px',
    // marginLeft: '10px',
    // marginRight: '10px',
    textAlign: "center",
    color: "white",
    fontSize: "30px",
  };

  //Container styles
  const dialContainerDiv = {
    height: "937.6px",
    width: "55%",
    minWidth: "210px",
    background: "linear-gradient(to top,#224663, #353839)",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    marginTop: "5px",
    marginBottom: "10px",
    marginLeft: "1%",
    marginRight: "0.10%",
    textAlign: "center",
    color: "black",
    fontSize: "16px",
    padding: "5px",
    // overflow: "auto",
  };

  const timelineContainerDiv = {
    height: "937.6px",
    width: "35%",
    minWidth: "613px",
    background: "linear-gradient(to top,#224663, #353839)",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    marginTop: "5px",
    marginBottom: "10px",
    marginLeft: "0.10%",
    marginRight: "1%",
    textAlign: "center",
    color: "black",
    fontSize: "16px",
    padding: "5px",
    //overflow: "auto"
  };

  const pumpCurveDiv = {
    width: "fit-content",
    minWidth: "635px",
    background: "linear-gradient(to bottom,#224663, #353839)",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    marginTop: "5px",
    marginBottom: "5px",
    minHeight: "635px",
    height: "fit-content",
    overflow: "auto",
    marginLeft: "2px",
    textAlign: "center",
    color: "white",
    fontSize: "22px",
    padding: "5px",
  };

  const tableChartDiv = {
    width: "45%",
    minWidth: "635px",
    background: "linear-gradient(to bottom,#224663, #353839)",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    marginTop: "5px",
    height: "auto",
    overflow: "visible",
    marginLeft: "2px",
    textAlign: "center",
    color: "black",
    fontSize: "22px",
    padding: "5px",
  };

  const registerSectionContainer = {
    width: "90%",
    minWidth: "635px",
    background: "linear-gradient(to top,#224663, #353839)",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    marginTop: "5px",
    height: "auto",
    overflow: "visible",
    marginLeft: "2px",
    textAlign: "center",
    color: "black",
    fontSize: "22px",
    padding: "5px",
  };

  //Register selector style
  const dashboardRegisterSelectorStyle = {
    maxWidth: "50%",
    minWidth: "50%",
    float: "left",
    height: "280px",
    border: "0.5px solid #005DAA",
    textAlign: "center",
    color: "slategrey",
    fontSize: "13px",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const tableRegisterSelectorStyle = {
    maxWidth: "50%",
    minWidth: "50%",
    float: "left",
    height: "350px",
    border: "0.5px solid #005DAA",
    marginTop: "15px",
    textAlign: "center",
    color: "slategrey",
    fontSize: "13px",
    borderRadius: "15px",
    overflow: "hidden",
  };

  //Date-time picker style
  const theme = createTheme({
    overrides: {
      MuiFormLabel: {
        root: {
          color: "red",
        },
      },
    },
    palette: {
      primary: {
        // primary color
        contrastText: "lightblue",
        dark: "#353839",
        main: "#353839",
      },
    },
  });

  if (renderDefaults != null) {
    return (
      <>
        <div style={mainBanner}>{deviceName}</div>

        <StatusRibbon device={deviceName} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={dialContainerDiv}>
            <Scrollbars
              style={{ width: "100%", height: "100%" }}
              renderThumbVertical={(props) => (
                <div style={{ backgroundColor: "#005DAA" }} />
              )}
            >
              <div style={subBanner}>Device Vitals</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(renderDefaults).map((keyName, i) =>
                  keyName.includes("text") ? (
                    <div
                      onClick={() => handleWidgetClick(renderDefaults[keyName])}
                      style={dialDiv}
                    >
                      <TextWidget
                        id="dial5"
                        register={renderDefaults[keyName]}
                        device={deviceName}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(renderDefaults).map((keyName, i) =>
                  keyName.includes("speedometer") ? (
                    <div
                      onClick={() => handleWidgetClick(renderDefaults[keyName])}
                      style={dialDiv}
                    >
                      <Speedometer
                        id="dial5"
                        register={renderDefaults[keyName]}
                        device={deviceName}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(renderDefaults).map((keyName, i) =>
                  keyName.includes("fillbar") ? (
                    <div
                      onClick={() => handleWidgetClick(renderDefaults[keyName])}
                      style={dialDiv}
                    >
                      <FillBar
                        id="dial5"
                        register={renderDefaults[keyName]}
                        device={deviceName}
                        color={"#5ED4F3"}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(renderDefaults).map((keyName, i) =>
                  keyName.includes("float") ? (
                    <div
                      onClick={() => alert("DIN plotting coming soon!")}
                      style={dialDiv}
                    >
                      <FloatSensor
                        id="dial5"
                        register={renderDefaults[keyName]}
                        device={deviceName}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {Object.keys(renderDefaults).map((keyName, i) =>
                  keyName.includes("thermometer") ? (
                    <div
                      onClick={() => handleWidgetClick(renderDefaults[keyName])}
                      style={dialDiv}
                    >
                      <Thermalmeter
                        id="dial5"
                        register={renderDefaults[keyName]}
                        device={deviceName}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {
                  <div
                    onClick={() => {
                      handleWidgetClick(32768);
                    }}
                    style={dialDiv}
                  >
                    <IrisInput
                      id="dial5"
                      register={32768}
                      device={deviceName}
                    />
                  </div>
                }
                {
                  <div
                    onClick={() => {
                      handleWidgetClick(32769);
                    }}
                    style={dialDiv}
                  >
                    <IrisInput
                      id="dial5"
                      register={32769}
                      device={deviceName}
                    />
                  </div>
                }
              </div>
            </Scrollbars>
          </div>

          <div style={timelineContainerDiv}>
            <div style={subBanner}>Event Timeline</div>
            <Timeline device={deviceName} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {Object.keys(renderDefaults).map((keyName, i) =>
            keyName.includes("pumpcurve") ? (
              <div style={pumpCurveDiv}>
                <div style={subBanner}>Live Pump Curve</div>

                <PumpCurve
                  device={deviceName}
                  pressureRegisterPSI={renderDefaults[keyName].psi}
                  flowRegisterGPM={
                    renderDefaults[keyName].gpm
                      ? renderDefaults[keyName].gpm
                      : false
                  }
                  motorSpeedRegisterRPM={
                    renderDefaults[keyName].rpm
                      ? renderDefaults[keyName].rpm
                      : false
                  }
                  tableRegisters={
                    renderDefaults[keyName].table
                      ? renderDefaults[keyName].table
                      : false
                  }
                  pumpModelCode={
                    renderDefaults[keyName].model != undefined
                      ? renderDefaults[keyName].model
                      : false
                  }
                />
              </div>
            ) : null
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={tableChartDiv}>
            <div style={subBanner}>Instant fetch datapoints</div>

            <InstantFetchTable
              device={deviceName}
              registerAddrList={registersTable}
              //here: pass registers that were selected and fetch them inside the component
            />
            <div style={tableRegisterSelectorStyle}>
              <Box component="form" noValidate sx={{ mr: 2, ml: 2 }}>
                <br />
                <br />
                <div>
                  <Select
                    defaultValue={{ label: "Choose one", value: "" }}
                    styles={styles}
                    margin="normal"
                    closeMenuOnSelect={false}
                    isMulti
                    options={regDropOpts}
                    placeholder={"Choose registers to view"}
                    value={registerInput3}
                    onChange={(option) => handleChange(option, 3)}
                  />
                </div>
                <br />
              </Box>
            </div>
          </div>
          <div style={tableChartDiv}>
            <div ref={chartRef} style={subBanner}>
              View datapoints over time{" "}
            </div>

            <FormControlLabel
              sx={{ color: "white", fontSize: "16px" }}
              control={
                <CustomSwitch
                  checked={live}
                  onChange={handleLiveSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Stream Real-Time Data"
            />
            {live ? (
              <>
                <DashboardChart
                  deviceName={deviceName}
                  startTime={startTime2}
                  stopTime={stopTime2}
                  registerAddrList={registersChart2}
                  chartOptions={liveOption}
                  refreshRate={9000}
                />
                <div style={dashboardRegisterSelectorStyle}>
                  <Box component="form" noValidate sx={{ mr: 2, ml: 2 }}>
                    <br />
                    <br />
                    <div>
                      <Select
                        styles={styles}
                        margin="normal"
                        closeMenuOnSelect={false}
                        isMulti
                        options={regDropOpts}
                        placeholder={"Registers to plot"}
                        value={registerInput2}
                        onChange={(option) =>
                          option.length <= 7
                            ? handleChange(option, 2)
                            : alert(
                                "A max of 3 registers can be plotted at a time on live chart"
                              )
                        }
                      />
                    </div>
                    <br />
                  </Box>
                </div>
              </>
            ) : (
              <>
                <DashboardChart
                  deviceName={deviceName}
                  startTime={startTime1}
                  stopTime={stopTime1}
                  registerAddrList={registersChartStaticLeft}
                  chartOptions={defaultOption}
                  refreshRate={1000000}
                />

                <div style={dashboardRegisterSelectorStyle}>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <br />
                      <DateTimePicker
                        renderInput={(props) => (
                          <TextField
                            variant="filled"
                            sx={{
                              backgroundColor: "white",
                              position: "relative",
                              zIndex: 0,
                            }}
                            {...props}
                          />
                        )}
                        label="Start Time"
                        value={startTime1UTC}
                        onChange={(input) => {
                          try {
                            var date = new Date(input);
                            setStartTime1(date.toISOString());
                            setStartTime1UTC(date.toISOString());
                          } catch (error) {
                            if (error instanceof RangeError) {
                              //pass (manual edit)
                            }
                          }
                        }}
                      />
                      <br />
                      <DateTimePicker
                        renderInput={(props) => (
                          <TextField
                            variant="filled"
                            sx={{
                              backgroundColor: "white",
                              position: "relative",
                              zIndex: 0,
                            }}
                            {...props}
                          />
                        )}
                        label="Stop Time"
                        value={stopTime1UTC}
                        onChange={(input) => {
                          try {
                            var date = new Date(input);
                            setStopTime1(date.toISOString());
                            setStopTime1UTC(date.toISOString());
                          } catch (error) {
                            if (error instanceof RangeError) {
                              //pass (manual edit)
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>

                  <div
                    style={{
                      color: "silver",
                      fontSize: "15px",
                      margin: "10px",
                    }}
                  >
                    <div>
                      Note: All data is plotted in UTC. Input will be converted
                      from your local time to UTC.
                    </div>
                    <div>Eastern Standard Time is 5 hours behind UTC</div>
                    <div>Eastern Daylight Time is 4 hours behind UTC</div>
                  </div>
                </div>

                <div style={dashboardRegisterSelectorStyle}>
                  <Box component="form" noValidate sx={{ mr: 2, ml: 2 }}>
                    <br />

                    <br />
                    <div>
                      <Select
                        styles={styles}
                        margin="normal"
                        closeMenuOnSelect={false}
                        isMulti
                        options={regDropOpts}
                        placeholder={"Select datapoints to plot"}
                        value={registerInputStaticLeft}
                        onChange={(option) =>
                          option.length <= 7
                            ? handleChange(option, 1)
                            : alert(
                                "A max of 7 registers can be plotted at a time on chart"
                              )
                        }
                      />
                    </div>

                    <br />
                  </Box>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={registerSectionContainer}>
            <div style={subBanner}>Sectioned I/O Hub</div>
            <IOSections deviceName={deviceName} />
          </div>
        </div>
      </>
    );
  } else {
    return <div>Loading...</div>;
  }
}
