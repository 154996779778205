import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { alpha, styled } from '@mui/material/styles';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: "#4CAF50",
    '&:hover': {
      backgroundColor: alpha("#4CAF50", theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor:"#4CAF50",
  },
}));


export default function UserDetails({setUserDetails, setSubmitClicked}) {


    const [fname, setFname] = React.useState("");
    const [lname, setLname] = React.useState("");
    const [addEmail, setAddEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [rePassword, setRePassword] = React.useState("");
    const [checkedAdmin, setCheckedAdmin] = React.useState(false);
    const [checkedRemoteCtrl, setCheckedRemoteCtrl] = React.useState(false);

    //User addition functions
    const handleCheckChangeAdmin = (event) => {
        setCheckedAdmin(event.target.checked);
        if(event.target.checked == true)
            setCheckedRemoteCtrl(event.target.checked);

    };
    const handleCheckChangeRC = (event) => {
        setCheckedRemoteCtrl(event.target.checked);
    };

    const handleAddUserClick = () => {

        setUserDetails({
            "first_name" : fname,
            "last_name" : lname,
            "admin_access" : checkedAdmin,
            "rc_access" : checkedRemoteCtrl,
            "email" : addEmail,
            "password" : password,
            "password_validate" : rePassword,
        })

        setSubmitClicked(1)


        
    //     var accessLvl;
    //     if (checked)
    //         accessLvl = "admin"
    //     else
    //         accessLvl = "non-admin"
        
    //     if (password !== rePassword) {
    //         alert("Passwords don't match");
    //     } 
        
    //     else {
    //         const opts = {
    
    //             method: 'POST',
    //             headers: {
    //               "Content-Type":"application/json"
    //             },
    //             body: JSON.stringify({
    //               "first_name": fname,
    //               "last_name": lname,
    //               "access_level":accessLvl,
    //               "email": addEmail,
    //               "password": password
    //             })
    //           }
    //           fetch('http://127.0.0.1:5000/auth/signup', opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
    //           .then(resp =>{
    //             if(resp.status === 200) return resp.json(); 
    //             else if (resp.status === 401) (console.error("401 error"))
    //           })
    //           .then(data => {
    //             console.log("New User MongoID:", data)
    //             setFname("")
    //             setLname("")
    //             setAddEmail("")
    //             setPassword("")
    //             setRePassword("")
    //             setCheckedAdmin(false)
    //             setCheckedRemoteCtrl(false)
    //           })
    //           .catch(error => {
    //             console.error("There was an error!", error)
    //           })  
    //     }
        
     }
    

    //Life is too short for the Submit Button. Enter key. 
    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            console.log("Enter pressed")
            //handleClick();
        }
    };
    

    return (
        <div>
            <div style={{paddingBottom:"10px",}}>
            <Box component="form" noValidate sx={{ mt: 1, mr:20, ml:2 }}>
                <br/>
                <h2 style={{color:"white"}}>Enter user details</h2>                
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="fname"
                            label="First Name"
                            name="fname"
                            autoComplete="name"
                            autoFocus
                            value = {fname}
                            onChange = {(e) => setFname(e.target.value)}
                            InputProps={{
                                style: {
                                    background: "white"
                                }
                            }}
                            InputLabelProps={{style: { backgroundColor: '#fff', borderRadius:"20px"}}}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lname"
                            label="Last Name"
                            name="lname"
                            autoComplete="name"
                            autoFocus
                            value = {lname}
                            onChange = {(e) => setLname(e.target.value)}
                            InputProps={{
                                style: {
                                    background: "white"
                                }
                            }}
                            InputLabelProps={{style: { backgroundColor: '#fff', borderRadius:"20px"}}}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value = {addEmail}
                            onChange = {(e) => setAddEmail(e.target.value)}
                            InputProps={{
                                style: {
                                    background: "white"
                                }
                            }}
                            InputLabelProps={{style: { backgroundColor: '#fff', borderRadius:"20px"}}}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value = {password}
                            onChange = {(e) => setPassword(e.target.value)}
                            InputProps={{
                                style: {
                                    background: "white"
                                }
                            }}
                            InputLabelProps={{style: { backgroundColor: '#fff', borderRadius:"20px"}}}                            
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Re-type Password"
                            type="password"
                            id="re-password"
                            autoComplete="current-password"
                            value = {rePassword}
                            onChange = {(e) => setRePassword(e.target.value)}
                            InputProps={{
                                style: {
                                    background: "white"
                                }
                            }}
                            InputLabelProps={{style: { backgroundColor: '#fff', borderRadius:"20px"}}}
                            //onKeyDown={handleKeypress}
                        />

                        <FormControlLabel sx ={{color:"white"}}
                            control={
                                <CustomSwitch
                                    checked={checkedAdmin}
                                    onChange={handleCheckChangeAdmin}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } 
                            label="Grant admin priviledges" 
                        />

                        <FormControlLabel sx ={{color:"white"}}
                            control={
                                <CustomSwitch
                                    checked={checkedRemoteCtrl}
                                    onChange={handleCheckChangeRC}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } 
                            label="Allow Remote Control" 
                        />

                        <Box m={1} pt={1}>
                        <div className="submit">
                            
                            <button
                            type="button"
                            onClick={handleAddUserClick}
                            className="add-btn"
                            >
                            <span>Confirm and Submit</span>
                            </button>
                            
                        </div>
                        </Box>
                    </Box>
                    </div>     
        </div>
    );
}
