import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Speedometer from "./widgets/speedometer";
import DeviceCard from "./cards";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import SearchBar from "material-ui-search-bar";

export default function HomeTiles() {
  const [categoryList, setCategoryList] = useState([]); //Stores in format: [{'category':"SDCP", 'devices':[device001, device002,...]}, {'category':"SDC", 'devices':[deviceXYZ, deviceYXZ,...]}]
  const [categoryListFiltered, setCategoryListFiltered] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [loading, setLoading] = useState(true);

  //Search Bar state vars
  const [searched, setSearched] = useState("");

  //Search handling
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    let filteredList = [];
    categoryList.forEach((categoryObj) => {
      let filteredDevices = categoryObj.devices.filter((device) =>
        device.toLowerCase().includes(searchedVal.toLowerCase())
      );
      if (filteredDevices.length > 0) {
        filteredList.push({
          category: categoryObj.category,
          devices: filteredDevices,
        });
      }
    });
    setCategoryListFiltered(filteredList);
  };

  const cancelSearch = () => {
    setSearched("");
    setCategoryListFiltered(categoryList);
  };

  //Populate menu with options to select from (data fetched from Inventory).

  //secret path to crash_logs download
  const navigate = useNavigate();
  var keypresses = [];
  document.addEventListener("keyup", function (e) {
    keypresses.push(e.key);
    if (keypresses.slice(-6).join("") === "secret") {
      navigate("/feconnect-crash-logs");
    }
  });
  function lastContactWithinDay(lastContact) {
    let lastContactDate = new Date(lastContact);
    let nowDate = new Date();
    let timeDiffHours =
      (nowDate.getTime() - lastContactDate.getTime()) / 1000 / 3600;

    if (timeDiffHours < 0) {
      // in case of RTC drift
      return true;
    } else return timeDiffHours > 24 ? false : true;
  }

  function getInventoryDetails() {
    fetch(
      "/api/inventory/authorized-devices?email=" +
        sessionStorage.getItem("email")
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        //Procesing fetched data to populate deviceList
        var processedDeviceData = [];
        for (const [category, devices] of Object.entries(data)) {
          processedDeviceData.push({ category: category, devices: devices }); //
        }

        setCategoryList(processedDeviceData);
        setCategoryListFiltered(processedDeviceData);
      })
      .catch((err) => {
        console.log(err);
      });

    fetch(
      "/api/inventory/device-status?email=" + sessionStorage.getItem("email")
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setStatusList(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    setLoading(true);
    getInventoryDetails();
  }, []);

  console.log("Category List", categoryList);

  const darkBlueStyle = {
    width: "30%",
    minWidth: 200,
    maxWidth: 200,
    float: "left",
    //height:"300px",
    //background:"#005DAA",
    margin: "5px",
    //border: '10px ridge rgba(0, 0, 0, 0.05)',
    borderRadius: "15px",

    //lineHeight: '300px',
    color: "white",
    //fontSize: "30px",
    overflow: "hidden",
  };

  return (
    <div
      style={{
        marginTop: "2%",
        marginRight: "10%",
        marginLeft: "10%",
        textAlign: "center",
      }}
    >
      <h1 style={{ color: "white" }}>
        Welcome to FE IRIS, {sessionStorage.getItem("first_name")} !
      </h1>
      <br />
      <h2 style={{ color: "white" }}>
        This is an overview of your products. You may also navigate to more
        detailed data from here.
      </h2>
      <br />
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
        placeholder="Start typing to filter IRIS units..."
        style={{
          margin: "10px auto",
          maxWidth: "60%",
          borderRadius: "15px",
          boxShadow: "3px 3px 3px 3px #005DAA",
        }}
      />
      <br />
      {loading ? (
        <div>
          <br />
          <br />
          <CircularProgress />
        </div>
      ) : (
        categoryListFiltered.map(function (categoryObj) {
          return (
            <>
              <div
                style={{
                  display: "inline-list-item",
                  justifyContent: "center",
                  marginLeft: "8%",
                }}
              >
                {categoryObj.devices.map((device, index) => (
                  <div style={darkBlueStyle}>
                    <DeviceCard
                      device={device}
                      category={categoryObj.category}
                      connectionQuality={
                        lastContactWithinDay(statusList[device]["last_contact"])
                          ? statusList[device][0]
                          : 5
                      }
                      connectionType={statusList[device][1]}
                      lastContact={statusList[device]["last_contact"]}
                    />
                  </div>
                ))}
              </div>
            </>
          );
        })
      )}
    </div>
  );
}
