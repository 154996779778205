import React, { useEffect, useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  dial: {
    display: "inline",
    padding: "3px",
    width: "100%",
  },
  title: {
    fontSize: "11pt",
    color: "#ffff",
  },
};

var reRenderer = true;
var renderCount = 1;

const Speedometer = ({ id, register, device }) => {
  const [details, setDetails] = useState({});
  const [error, setError] = useState(false);
  const [dialValue, setDialValue] = useState(0);
  const [loadingRegisterDetails, setLoadingRegisterDetails] = useState(true);
  const [loadingRegisterValues, setLoadingRegisterValues] = useState(true);

  useEffect(() => {
    fetch(
      "/api/telemetry/registerdetails?device_name=" +
        device +
        "&register=" +
        register
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setDetails(data);
        if (
          data === undefined ||
          data.name === undefined ||
          data.unit === undefined ||
          data.scale === undefined ||
          data.min === undefined ||
          data.max === undefined
        ) {
          setError(true);
        } else setLoadingRegisterDetails(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  const getRegisterData = () => {
    // const windowUrl = window.location.search;
    // const params = new URLSearchParams(windowUrl);
    // device = params.get('device_name')

    //newest value of register of device
    fetch(
      "/api/telemetry/dialdata?device_name=" + device + "&register=" + register
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        else if (resp.status === 500) {
          setLoadingRegisterValues(false);
          setLoadingRegisterDetails(false);
          setError(true);
        }
      })
      .then((data) => {
        setDialValue(data.register_value);
        setError(false);
        if (data === undefined || data.register_value === undefined) {
          setError(true);
        } else setLoadingRegisterValues(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });

    renderCount += 1;

    if (renderCount > 15) {
      reRenderer = false;
    }
  };

  useEffect(() => {
    const getAPIData = async () => {
      try {
        await getRegisterData(device);
      } catch (err) {
        console.log(err);
      }
    };

    getAPIData();

    const interval = setInterval(() => {
      //device = searchParams.get("device_name")
      //console.log(device)

      getAPIData();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  if (loadingRegisterDetails || loadingRegisterValues)
    return <CircularProgress size="1.25rem" />;
  else {
    return (
      <div style={styles.dial}>
        {error ? (
          <div style={styles.title}>
            No data available
            <br />
            Check IRIS configuration
          </div>
        ) : (
          <>
            <ReactSpeedometer
              maxValue={Math.round(details.max * 1e2) / 1e2}
              minValue={details.min}
              forceRender={reRenderer}
              //fluidWidth={true}
              height={130}
              width={205}
              value={Math.round((dialValue / details.scale) * 1e2) / 1e2}
              needleTransition="easeLinear"
              needleTransitionDuration={2000}
              needleColor="white"
              needleHeightRatio={0.6}
              startColor="#1ED760"
              maxSegmentLabels={2} //5
              segments={1} //5
              endColor="#1ED760" //blue
              textColor="white"
              ringWidth={10}
            />
            <div style={styles.title}>
              {details.name + " (" + details.unit + ")"}
            </div>
          </>
        )}
      </div>
    );
  }
};

export default Speedometer;
