import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import SearchBar from "material-ui-search-bar";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import { styled } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: "90%",
    margin: "auto",
    backgroundColor: "rgba(0,0,0,0)",
    marginTop: 12,
    "& .MuiListItem-root": {
      color: "white",
      "&:hover": {
        color: "#1ED760",
      },
      "&.MuiListItem-divider": {
        borderBottom: "2px solid rgba(0,0,0,0.1)",
      },
    },
  },
}));

const CustomRadio = withStyles({
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#1ED760",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function IRISFirmware({ setFWSelection }) {
  const [fetchedListData, setFetchedListData] = useState([]);
  const [filteredListData, setFilteredListData] = useState([]);
  const [fetchComplete, setFetchConplete] = useState(false);
  const [checked, setChecked] = useState();
  //var checkedOptions = []; //use if usestates don't sync

  useEffect(() => {
    fetch("/api/feconnect-fw") /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setFetchedListData(data);
        setFilteredListData(data);
        setFetchConplete(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const classes = useStyles();

  const handleToggle = (fw_selection) => () => {
    if (fw_selection.version == checked) {
      setChecked();
      setFWSelection();
    } else if (fw_selection.version != checked) {
      setChecked(fw_selection.version);
      setFWSelection(fw_selection);
    }

    // console.log("Value:", fw_selection);
    // console.log("Checked:", checked);
  };

  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    const filteredItems = fetchedListData.filter((item) => {
      return item.version.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilteredListData(filteredItems);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return (
    <>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
        placeholder="Filter firmware"
        style={{
          margin: "10px auto",
          maxWidth: "90%",
        }}
      />
      {fetchComplete ? (
        <>
          <List className={classes.listRoot}>
            {/* {console.log(filteredListData)} */}
            {filteredListData.map((fw_row, index) => {
              const labelId = `checkbox-list-label-${fw_row.version}`;

              return (
                <ListItem
                  alignItems="flex-start"
                  key={fw_row.version}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(fw_row)}
                  divider
                >
                  <ListItemIcon>
                    <FormControlLabel
                      control={
                        <CustomRadio
                          edge="start"
                          checked={checked == fw_row.version}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    disableTypography
                    primary={
                      <Typography
                        style={{ fontSize: "16px", color: "#FFFFFF" }}
                      >
                        {"v" + fw_row.version}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        style={{
                          fontSize: "11px",
                          fontStyle: "italic",
                          color: "#FFFFFF",
                        }}
                      >
                        {fw_row.publish_date}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
          </List>

          <br />
        </>
      ) : (
        <div>
          <br />
          <br />
          <CircularProgress />
        </div>
      )}
    </>
  );
}
