import { useState } from "react";
import '../../css/addDevice.css'


function AddDevice() {
  const [deviceList, setDeviceList] = useState([{ device: "", type: "", users: "" }]);

  const handleSubmit = (e, index) => {
    
    var filteredDeviceList = deviceList.filter(e => e.device !== '') //removing empty values where deviceList.device=""
    
    const opts = {
    
      method: 'POST',
      headers: {
        "Content-Type":"application/json"
      },
      body: JSON.stringify({
        filteredDeviceList
      })
    }
    fetch('/api/inventory/add', opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
    .then(resp =>{
      if(resp.status === 200) return resp.json(); 
      else (alert("Error"))
    })
    .then(data => {
      console.log("Response:", data)
      setDeviceList([{ device: "", type: "", users: "" }])
    })
    .catch(error => {
      alert()
      console.error("There was an error!", error)
    })    

  };

  const handleChangeDevice = (e, index) => {
    const { name, value } = e.target;
    const list = [...deviceList];
    list[index][name] = value;
    setDeviceList(list);
  };

  const handleRemove = (index) => {
    const list = [...deviceList];
    list.splice(index, 1);
    setDeviceList(list);
  };

  const handleAdd = () => {
    setDeviceList([...deviceList, { device: "" }]);
  };

  return (
    <div style={{margin:"100px", color:"white", background:"linear-gradient(to left, #005DAA, #353839)"}}>
      <form className="form" autoComplete="off">
      <div className="form-field">
        <label><h1 style={{color:"white"}}>Enroll your devices here:</h1></label>
        <br/>
        <p>
          Enter the name of the device(s) to be added to the dashboard. This name must match the Device Name that is transmitted by the device.
        </p>
        <br/>
        <p>
          Specify the device type. 
        </p>
        <br/>
        <p>
          Authorize users that with access to device. All users with admin priviledges are granted access automatically.
        </p>
        <br/>
        {deviceList.map((singleDevice, index) => (
          <div key={index} className="devices">
            <div className="first-division">
              <input
                name="device"
                placeholder="Device Name"
                type="text"
                id="device"
                value={singleDevice.device}
                onChange={(e) => handleChangeDevice(e, index)}
                required
              />
              <input
                name="type"
                placeholder="Device Type (Options: SDC/SDCP/SMC/X-Drive)"
                type="text"
                id="type"
                value={singleDevice.type}
                onChange={(e) => handleChangeDevice(e, index)}
                required
              />
              <input
                name="users"
                placeholder="List users to grant access (comma-separated registered emails)"
                type="text"
                id="users"
                value={singleDevice.users}
                onChange={(e) => handleChangeDevice(e, index)}
                required
              />
              <br/>
              {deviceList.length - 1 === index && deviceList.length < 10 && (
                <button
                  type="button"
                  onClick={handleAdd}
                  className="add-btn"
                >
                  <span>Enroll more devices</span>
                </button>
              )}
              <div className="submit">
                {deviceList.length - 1 === index && (
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="add-btn"
                >
                  <span>Confirm and Submit</span>
                </button>
                )}
              </div>
              
              
            </div>
            <div className="second-division">
              {deviceList.length !== 1 && (
                <button
                  type="button"
                  onClick={() => handleRemove(index)}
                  className="remove-btn"
                >
                  <span>Remove</span>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </form>
    </div>
    
  );
}

export default AddDevice;
