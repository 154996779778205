import React from "react";
import { useState, useEffect } from "react";
import { Chrono } from "react-chrono";
import AnnouncementIcon from "@mui/icons-material/Announcement";

// FORMAT OF DATA:
//const data = [
//     {
//         title: "May 1940",
//         cardTitle: "The Battle of Britain",
//         cardDetailedText: `On 10 May 1940`
//     },
//     {
//         title: "May 1940",
//         cardTitle: "The Battle of Britain",
//         cardDetailedText: `On 10 May 1940`
//     },
//     {
//         title: "May 1940",
//         cardTitle: "The Battle of Britain",
//         cardDetailedText: `On 10 May 1940`
//     },
//     {
//         title: "May 1940",
//         cardTitle: "The Battle of Britain",
//         cardDetailedText: `On 10 May 1940`
//     }
//   ];

function splitCardDetailedText(data) {
  return data.map((item) => {
    // Check if cardDetailedText contains the pipe symbol
    if (
      typeof item.cardDetailedText === "string" &&
      item.cardDetailedText.includes(" • ")
    ) {
      // Split the text by the pipe symbol and trim each resulting string
      item.cardDetailedText = item.cardDetailedText
        .split(" • ")
        .map((text) => text.trim());
    }
    return item;
  });
}

export default function Timeline({ device }) {
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {}, []);

  const getTimelineData = () => {
    fetch("/api/stats/event-timeline?device_name=" + device) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        data = splitCardDetailedText(data);
        setTimelineData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const getAPIData = async () => {
      try {
        await getTimelineData(device);
      } catch (err) {
        console.log(err);
      }
    };

    getAPIData();

    const interval = setInterval(() => {
      getAPIData();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
        height: "97%",
        padding: "1%",
      }}
    >
      {timelineData ? (
        <Chrono
          items={timelineData}
          mode="VERTICAL_ALTERNATING"
          focusActiveItemOnLoad
          activeItemIndex={0}
          enableOutline
          allowDynamicUpdate={1}
          timelineCircleDimension={0}
          cardHeight={100}
          theme={{
            primary: "#005DAA",
            secondary: "#1ED760",
            cardBgColor: "white",
            cardForeColor: "black",
            titleColor: "#ffffff",
            titleColorActive: "black",
          }}
          fontSizes={{
            cardSubtitle: "13px",
            cardText: "13px",
            cardTitle: "13px",
            title: "13px",
          }}
          buttonTexts={{
            first: "Jump to First",
            last: "Jump to Last",
            next: "Next",
            previous: "Previous",
          }}
        >
          <div className="chrono-icons">
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
            <AnnouncementIcon style={{ fontSize: "20px", color: "white" }} />
          </div>
        </Chrono>
      ) : (
        <div style={{ color: "white" }}>No timeline data to display</div>
      )}
    </div>
  );
}
