import { useNavigate } from "react-router-dom";
import { CardHeader } from "@mui/material";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { IconButton } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import Tooltip from "@mui/material/Tooltip";
import WarningIcon from "@mui/icons-material/Warning";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import DeleteIcon from "@mui/icons-material/Delete";
import { makeStyles } from "@mui/styles";
import Popover from "@mui/material/Popover";
import CircularProgress from "@mui/material/CircularProgress";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import SimCardIcon from "@mui/icons-material/SimCard";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 5,
    minWidth: 200,
    transition: "transform 0.15s ease-in-out, background-color 0.5s ease",
    "&:hover": {
      transform: "scale3d(1.05, 1.05, 1) rotate(-2deg)",
      background: "linear-gradient(to top, #005DAA, #353935)",
      color: "white",
    },
    margin: "auto",
  },

  media: {
    height: 100,
    width: 100,
    margin: "auto",
  },
}));

export default function DeviceCard({
  device,
  category,
  connectionQuality,
  connectionType,
  lastContact,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const lastContactLocaleString = new Date(lastContact).toLocaleString();

  const connectionQualityColors = [
    "#81c784",
    "#81c784",
    "#ffb74d",
    "#ffb74d",
    "#e57373",
    "#e57373",
  ]; // excellent(0)| good(1) | fair(2) | degraded(3)| disconnected(4) |"No contact for over 24 hours"(5)
  const connectionQualityStrings = [
    "Excellent",
    "Good",
    "Fair",
    "Degraded",
    "Disconnected",
    "No contact for over 24 hours",
  ];

  const connectionTypeIcons = [
    <SimCardIcon
      sx={{
        fontSize: "25px",
        color: connectionQualityColors[connectionQuality],
      }}
    />,
    <SignalWifi4BarIcon
      sx={{
        fontSize: "25px",
        color: connectionQualityColors[connectionQuality],
      }}
    />,
  ]; //cellular(0), wifi(1)
  const connectionTypeStrings = ["Cellular", "WiFi"];

  const navigateTo = (link) => {
    navigate(link);
  };
  console.log(device, category, connectionQuality, connectionType);

  //data fetch state managers
  const [statusData, setStatusData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [devView, setDevView] = useState(false);

  //Popover methods and state management:
  const [anchorEl, setAnchorEl] = React.useState(null);
  function fetchStatusData() {
    fetch("/api/stats/latest-status?device_name=" + device) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setStatusData(data);
        setLoaded(1);
        console.log(statusData);
        console.log(device);
      })
      .catch((err) => {
        setLoaded(0);
        console.log(err);
      });
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    //fetch data
    fetchStatusData();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  var keypresses = [];
  document.addEventListener("keyup", function (e) {
    keypresses.push(e.key);
    if (keypresses.slice(-4).join("") === "view") {
      setDevView(!devView);
    }
  });
  return (
    <Card className={classes.root} elevation={15}>
      <CardHeader
        action={
          // <Tooltip title="Remove from home">
          //     <IconButton onClick={() => alert("clicked Delete")}>
          //         <DeleteIcon/>
          //     </IconButton>
          // </Tooltip>
          <>
            {/* ******network icon****** */}
            <div
              title={
                connectionTypeStrings[connectionType] +
                ": " +
                connectionQualityStrings[connectionQuality] +
                " \nLast contact: " +
                lastContactLocaleString
              }
            >
              {connectionTypeIcons[connectionType]}
            </div>

            {/* ******connection qualtiy icon****** TODO:CHANGE THIS TO ERROR_CODE icon*/}
            {/* <div title={connectionQualityStrings[connection]}>
              <CircleIcon
                style={{
                  color: connectionQualityColors[connection],
                  fontSize: "12px",
                }}
              />
            </div> */}
          </>
        }
        title={
          <>
            <p style={{ textAlign: "center", fontSize: "14px" }}>
              {" " + device}
            </p>
            <p style={{ textAlign: "center", fontSize: "10px" }}>
              <b>
                {devView &&
                  connectionTypeStrings[connectionType] +
                    ": " +
                    connectionQualityStrings[connectionQuality]}
              </b>
            </p>
            <p style={{ textAlign: "center", fontSize: "10px" }}>
              <b>{devView && "Last contact: " + lastContactLocaleString}</b>
            </p>
            <p style={{ textAlign: "center", fontSize: "10px" }}>
              {loaded ? (
                <>
                  {devView &&
                    statusData.map((tile, index) => (
                      <div>
                        <b>{tile.name + ": "}</b>
                        {tile.value +
                          " (" +
                          new Date(tile.time_stamp).toLocaleString() +
                          ")"}
                      </div>
                    ))}
                </>
              ) : (
                devView &&
                fetchStatusData() && (
                  <div>
                    Loading...
                    <CircularProgress size="1rem" />
                  </div>
                )
              )}
            </p>
          </>
        }
      />
      <CardMedia
        component="img"
        className={classes.media}
        image={"/" + category + ".png"}
        alt={category}
      />
      <CardContent>
        <Tooltip title="View Telemetry Data">
          <IconButton
            sx={{ "&:hover": { color: "#f2f2f2" } }}
            onClick={() => navigateTo("/device-dash?device_name=" + device)}
          >
            <QueryStatsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="View Status">
          <IconButton
            sx={{ "&:hover": { color: "#f2f2f2" } }}
            onClick={(e) => handleClick(e)}
          >
            <WarningIcon />
          </IconButton>
        </Tooltip>
        <Popover
          style={{ padding: "20px" }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {loaded ? (
            <>
              {statusData.map((tile, index) => (
                <div
                  style={{
                    background: "linear-gradient(to right, #95d2ea, #ddd)",
                  }}
                >
                  <b>{tile.name + ": "}</b>
                  {tile.value +
                    " (" +
                    new Date(tile.time_stamp).toLocaleString() +
                    ")"}
                </div>
              ))}
            </>
          ) : (
            <div>
              Loading...
              <CircularProgress size="1rem" />
            </div>
          )}
        </Popover>
      </CardContent>
    </Card>
  );
}
