import Select from "react-select"
import Box from '@mui/material/Box';
import { useEffect, useState} from 'react';
import FileUploadTool from "../components/fileUploader";
import ConfigSelectDeploy from "../components/configSelection";
import CommandSelection from "../components/commandSelection";
import ArgInputDeploy from "../components/argumentInputDeploy";
import ControlDeviceSelection from "../components/controlDeviceSelection";

export default function RemoteControlPanel() {

    const [selectedDevices, setSelectedDevices] = useState([])
    const [selectedCommand, setSelectedCommand] = useState([])
    const [disableControl, setDisableControl] = useState(true)

    // Inline-Styles for divs
    // Banner styles  
    const mainBannerDiv = {
        width: "99%",
        minWidth: "99%",
        marginLeft: "10px",
        marginRight: "10px",
        height:"40px", 
        background:"#224663", 
        border: '0.1px solid #1ED760',
        borderRadius: '15px',
        marginTop: '3px',
        textAlign: "center",
        lineHeight: "2.5",
        color: "white",
        fontSize: "16px",
    }

    const containerDiv = {
        position:"relative",
        height:"auto",
        minHeight:"40vh",
        width: "80%",
        minWidth: "306px",
        background:"linear-gradient(to top,#224663, #353839)",
        border: '0.5px solid #005DAA',
        borderRadius: '15px',
        marginTop: '15px',
        marginBottom: '10px',
        marginLeft: "1%",
        marginRight: "0.10%",
        textAlign: "center",
        padding:'5px',
        overflow: "auto",
        color: "white",
        padding:'5px',
    }

    const smallContainerDiv = {
        position:"relative",
        height:"auto",
        minHeight:"50vh",
        width: "40%",
        minWidth: "40%",
        background:"linear-gradient(to top,#224663, #353839)",
        border: '0.5px solid #005DAA',
        borderRadius: '15px',
        marginTop: '5px',
        marginLeft: "1%",
        marginRight: "0.10%",
        textAlign: "center",
        padding:'5px',
        overflow: "auto",
        color: "white",
        padding:'5px',

        opacity: disableControl ? 0.25 : 1,
        pointerEvents: disableControl ? "none" : "initial"
    }

    const bannerDiv = {
        border: '0.3px solid white',
        borderRadius: '15px',
        color: "white",
        fontSize: "16px",
        margin:"3px"
    }

    console.log("disableControl", disableControl)
  return (
    <div style ={{marginLeft:"30px", marginRight:"30px", marginBottom:"30px"}}>
        <div style={mainBannerDiv}>Remote Control Panel</div>
    
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', flexWrap:'wrap'}}>
            
            {////////// DEVICES //////////
            } 
            <div style = {containerDiv}>
                <div style = {bannerDiv}>Select Device</div>
                
                <Box component="form" noValidate sx={{ mr:2, ml:2 }}>
                    <div>
                        <ControlDeviceSelection setSelectedDevices={setSelectedDevices} setDisableControl = {setDisableControl}/>
                    </div>
                </Box>
            </div>
        </div>

        <div style={{display: 'flex',  justifyContent:'center'}}>
            
            {////////// CONFIGS //////////
            }
            <div style = {smallContainerDiv}>
                <div style = {bannerDiv}>Configure </div>
                
                    <br/><br/>
                    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <FileUploadTool/>
                    </div>
                <br/>
                <br/>                               
                    <div>
                        <ConfigSelectDeploy selectedDevices={selectedDevices} disableControl={disableControl}/>
                    </div>
                
            </div>

            {////////// COMMANDS //////////
            }
            <div style = {smallContainerDiv}>
                <div style = {bannerDiv}>Command</div>

                <br/><br/>
                    <CommandSelection setSelectedCommand={setSelectedCommand} disableControl={disableControl}/>
                <br/><br/>
                <div>
                    <ArgInputDeploy selectedDevices={selectedDevices} selectedCommand={selectedCommand}/>
                </div>
            </div>
            
            
        </div>
        
    </div>
    
  )
}
