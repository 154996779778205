import {useState, useEffect} from 'react'
import FirstTimePopUp from '../components/firstTimePopUp';
import HomeDashboard from '../components/homeDashboard'


function Landing() {
    
    //Unlike the Pyhtonic bool provided by Flask, the string "false" evaluates to true in JavaScript. Hence we string match here:
    const [firstTime, setFirstTime] = useState(sessionStorage.getItem("first_time") == "true");
    //console.log(firstTime)
    //console.log(sessionStorage.getItem("first_time"))
    return( 
        <>
        {   
            firstTime?(<FirstTimePopUp firstTime = {firstTime} setFirstTime={setFirstTime}/>):(<HomeDashboard/>)
        }
        </>
        
            
    )
}

export default Landing