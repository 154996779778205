import { Spring } from "react-spring";
import React, { useEffect, useState } from "react";
import "../../css/fillBar.css";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  dial: {
    display: "inline",
    padding: "3px",
    width: "100%",
  },
  title: {
    fontSize: "11pt",
    color: "#ffff",
  },
};

const FillBar = ({ register, device, color }) => {
  const [details, setDetails] = useState({});
  const [error, setError] = useState(false);
  const [dialValue, setDialValue] = useState(0);
  const [loadingRegisterDetails, setLoadingRegisterDetails] = useState(true);
  const [loadingRegisterValues, setLoadingRegisterValues] = useState(true);

  useEffect(() => {
    fetch(
      "/api/telemetry/registerdetails?device_name=" +
        device +
        "&register=" +
        register
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        else if (resp.status === 500) {
          setLoadingRegisterValues(false);
          setLoadingRegisterDetails(false);
          setError(true);
        }
      })
      .then((data) => {
        setDetails(data);
        if (
          data === undefined ||
          data.name === undefined ||
          data.unit === undefined ||
          data.scale === undefined ||
          data.min === undefined ||
          data.max === undefined
        ) {
          setError(true);
        } else setLoadingRegisterDetails(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  useEffect(() => {
    if ((dialValue / details.scale / (details.max - details.min)) * 100 < 20) {
      //console.log(((dialValue/details.scale)/(details.max-details.min))*100)
      color = "red";
    }
  }, [dialValue]);

  const getRegisterData = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    device = params.get("device_name");

    //newest value of register of device
    fetch(
      "/api/telemetry/dialdata?device_name=" + device + "&register=" + register
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setDialValue(data.register_value);

        setError(false);
        if (data === undefined || data.register_value === undefined) {
          setError(true);
        } else setLoadingRegisterValues(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  useEffect(() => {
    const getAPIData = async () => {
      try {
        await getRegisterData(device);
      } catch (err) {
        console.log(err);
      }
    };

    getAPIData();

    const interval = setInterval(() => {
      //device = searchParams.get("device_name")
      //console.log(device)

      getAPIData();
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  if (loadingRegisterDetails || loadingRegisterValues)
    return <CircularProgress size="1.25rem" />;
  else {
    return (
      <>
        {error ? (
          <div style={styles.title}>No data available</div>
        ) : (
          <>
            <Spring
              from={{ percent: 0 }}
              to={{
                percent:
                  (dialValue / details.scale / (details.max - details.min)) *
                  100,
              }}
            >
              {({}) => (
                <div className="progress vertical">
                  {/* {console.log("Value:", dialValue)}
                  {console.log("Scale:", details.scale)}
                  {console.log("Divisor:", details.max - details.min)}
                  {console.log(
                    "Result:",
                    `${
                      (dialValue /
                        details.scale /
                        (details.max - details.min)) *
                      100
                    }%`
                  )} */}
                  <div
                    style={{
                      minHeight: "20%",
                      height: `${
                        (dialValue /
                          details.scale /
                          (details.max - details.min)) *
                        100
                      }%`,
                      backgroundColor:
                        (dialValue /
                          details.scale /
                          (details.max - details.min)) *
                          100 >
                        21
                          ? color
                          : "red",
                    }}
                    className="progress-bar"
                  >
                    <span>
                      <h1>
                        {(dialValue / details.scale).toFixed(1) +
                          " " +
                          details.unit}
                      </h1>
                    </span>
                  </div>
                </div>
              )}
            </Spring>
          </>
        )}

        {error ? (
          <div style={styles.title}>Check IRIS configuration</div>
        ) : (
          <div style={styles.title}>
            {details.name + (details.unit ? " (" + details.unit + ")" : "")}
          </div>
        )}
        <br />
      </>
    );
  }
};

export default FillBar;
