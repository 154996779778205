import { TextField } from "@mui/material";
import { useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";

export default function Writer({ deviceName, regAddress }) {
  const [input, setInput] = useState("");
  const rc_access =
    sessionStorage.getItem("rc_access") === "true" ? true : false;

  console.log(rc_access);
  const WriteButton = () => (
    <IconButton edge="end" onClick={() => handleClick()}>
      <CreateIcon style={{ color: "#1976D2" }} />
    </IconButton>
  );

  const logger = (argument) => {
    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: sessionStorage.getItem("email"),
        device: deviceName,
        log_type: "command",
        timestamp: new Date().getTime().toString(),
        log: "CMD_WRITE" + " " + argument,
      }),
    };
    fetch("/api/add-user-logs", opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        else if (resp.status === 401) console.error("401 error");
      })
      .then((data) => {
        console.log("Publish Result:", data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleClick = () => {
    var argument = regAddress + "," + input;

    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_list: deviceName,
        command: "CMD_WRITE",
        arguments: argument,
      }),
    };

    fetch("/api/control/command", opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        else if (resp.status === 401) console.error("401 error");
      })
      .then((data) => {
        console.log("Publish Result:", data);
        logger(argument);
        setInput("");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <TextField
      disabled={!rc_access}
      variant="outlined"
      placeholder="Write to register"
      size="small"
      value={input}
      onChange={(e) => setInput(e.target.value)}
      InputProps={{ style: { fontSize: 13 }, endAdornment: <WriteButton /> }}
    />
  );
}
