import Select from "react-select"
import Box from '@mui/material/Box';
import { useEffect, useState} from 'react';
import FileUploadTool from "../components/fileUploader";
import ConfigSelectDeploy from "../components/configSelection";
import NotificationCreator from "../components/notificationCreate";
import ArgInputDeploy from "../components/argumentInputDeploy";

import NotificationDeviceSelection from "../components/notificationDeviceSelection";

export default function Notifications() {

    const [selectedDevices, setSelectedDevices] = useState([])
    const [selectedCommand, setSelectedCommand] = useState([])
    const [disableControl, setDisableControl] = useState(true)

    // Inline-Styles for divs
    // Banner styles  
    const mainBannerDiv = {
        width: "99%",
        minWidth: "99%",
        marginLeft: "10px",
        marginRight: "10px",
        height:"40px", 
        background:"#224663", 
        border: '0.1px solid #1ED760',
        borderRadius: '15px',
        marginTop: '3px',
        textAlign: "center",
        lineHeight: "2.5",
        color: "white",
        fontSize: "16px",
    }

    const deviceSelectionDiv = {
        position:"relative",
        height:"auto",
        minHeight:"40vh",
        width: "80%",
        minWidth: "306px",
        background:"linear-gradient(to top,#224663, #353839)",
        border: '0.5px solid #005DAA',
        borderRadius: '15px',
        marginTop: '15px',
        marginBottom: '10px',
        marginLeft: "1%",
        marginRight: "0.10%",
        textAlign: "center",
        padding:'5px',
        overflow: "auto",
        color: "white",
        padding:'5px',
    }

    const notificationCreationDiv = {
        position:"relative",
        height:"auto",
        minHeight:"40vh",
        width: "80%",
        minWidth: "306px",
        background:"linear-gradient(to top,#224663, #353839)",
        border: '0.5px solid #005DAA',
        borderRadius: '15px',
        marginTop: '5px',
        marginLeft: "1%",
        marginRight: "0.10%",
        textAlign: "center",
        padding:'5px',
        overflow: "auto",
        color: "white",
        padding:'5px',

        opacity: disableControl ? 0.25 : 1,
        pointerEvents: disableControl ? "none" : "initial"
    }

    const bannerDiv = {
        border: '0.3px solid white',
        borderRadius: '15px',
        color: "white",
        fontSize: "16px",
        margin:"3px"
    }

  return (
    <div style ={{marginLeft:"30px", marginRight:"30px", marginBottom:"30px"}}>
        <div style={mainBannerDiv}>Notifications</div>
    
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', flexWrap:'wrap'}}>
            
            {////////// DEVICES //////////
            } 
            <div style = {deviceSelectionDiv}>
                <div style = {bannerDiv}>Select Device</div>
                
                <Box component="form" noValidate sx={{ mr:2, ml:2 }}>
                    <div>
                        <NotificationDeviceSelection setSelectedDevices={setSelectedDevices} setDisableControl = {setDisableControl}/>
                    </div>
                </Box>
            </div>
        </div>

        <div style={{display: 'flex',  justifyContent:'center'}}>
            
            {////////// WIZARD //////////
            }
            <div style = {notificationCreationDiv}>
                <div style = {bannerDiv}>Create and Delpoy Notifications</div>
                
                    <NotificationCreator selectedDevices = {selectedDevices} setSelectedCommand={setSelectedCommand} disableControl={disableControl}/>
                
                
            </div>
            
            
        </div>
        
    </div>
    
  )
}
