import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import SignalCellularConnectedNoInternet1BarIcon from "@mui/icons-material/SignalCellularConnectedNoInternet1Bar";
import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: "90%",
    margin: "auto",
    backgroundColor: "rgba(0,0,0,0)",
    marginTop: 12,
    "& .MuiListItem-root": {
      color: "white",
      "&:hover": {
        color: "#1ED760",
      },
      "&.MuiListItem-divider": {
        borderBottom: "2px solid rgba(0,0,0,0.1)",
      },
    },
  },
}));

const CustomCheckbox = withStyles({
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#1ED760",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function IRISList({ setIrisSelection }) {
  const [fetchedListData, setFetchedListData] = useState([]);
  const [filteredListData, setFilteredListData] = useState([]);
  const [fetchComplete, setFetchConplete] = useState(false);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    fetch("/api/inventory/iris-list?email=" + sessionStorage.getItem("email")) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setFetchedListData(data);
        setFilteredListData(data);
        setFetchConplete(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const classes = useStyles();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setIrisSelection(newChecked);
  };

  const [searched, setSearched] = useState("");

  const requestSearch = (searchedVal) => {
    const filteredItems = fetchedListData.filter((item) => {
      return item.device_name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setFilteredListData(filteredItems);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return (
    <>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
        placeholder="Filter devices"
        style={{
          margin: "10px auto",
          maxWidth: "90%",
        }}
      />
      {fetchComplete ? (
        <>
          <List className={classes.listRoot}>
            {/* {console.log(filteredListData)} */}
            {filteredListData.map((row, index) => {
              const labelId = `checkbox-list-label-${row.device_name}`;

              return (
                <ListItem
                  alignItems="flex-start"
                  key={row.device_name}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(row.device_name)}
                  divider
                >
                  <ListItemIcon>
                    <FormControlLabel
                      control={
                        <CustomCheckbox
                          edge="start"
                          checked={checked.indexOf(row.device_name) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      }
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    disableTypography
                    primary={
                      <Typography
                        style={{ fontSize: "16px", color: "#FFFFFF" }}
                      >
                        {row.device_name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        style={{
                          fontSize: "11px",
                          fontStyle: "italic",
                          color: "#FFFFFF",
                        }}
                      >
                        {"version: " + row.fw_version}
                      </Typography>
                    }
                  />

                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="comments">
                      <SignalCellularConnectedNoInternet1BarIcon
                        style={{ color: "yellow" }}
                      />
                    </IconButton>
                    <IconButton edge="end" aria-label="comments">
                      <NetworkWifiIcon style={{ color: "white" }} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>

          <br />
        </>
      ) : (
        <div>
          <br />
          <br />
          <CircularProgress />
        </div>
      )}
    </>
  );
}
