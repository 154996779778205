import {useState, useEffect, useCallback} from 'react'
import UserDetails from '../../components/userDetails';
import '../../css/userAdd.css'
import '../../css/addDevice.css'
import UserDevices from '../../components/userDevices';

var createUserFetchReq = {}

export default function UserCreate() {

  //On clicking submit update User DB with new user document.
  const handleAddUserClick = () => {
    
    const opts = {

        method: 'POST',
        headers: {
          "Content-Type":"application/json"
        },
        body: JSON.stringify({
          "first_name": createUserFetchReq.first_name,
          "last_name": createUserFetchReq.last_name,
          "admin_access": createUserFetchReq.admin_access,
          "rc_access": createUserFetchReq.rc_access,
          "email": createUserFetchReq.email,
          "password": createUserFetchReq.password,
          "device_list": createUserFetchReq.device_list
        })
      }
      fetch('/api/auth/signup', opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
      .then(resp =>{
        if(resp.status === 200) return resp.json(); 
        else if (resp.status === 401) (console.error("401 error"))
      })
      .then(data => {
        console.log("New User MongoID:", data)
        //refresh
        setUserDetails({})
        setSubmitClicked(0)
        setDeviceList([])
      })
      .catch(error => {
        console.error("There was an error!", error)
      })      

      //Update Inventory DB with new user added to device
      const options = {
    
        method: 'POST',
        headers: {
          "Content-Type":"application/json"
        },
        body: JSON.stringify({
          createUserFetchReq
        })
      }
  
      fetch('/api/inventory/signup', options) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
        .then(resp =>{
          if(resp.status === 200) return resp.json(); 
          else (alert("Error"))
        })
        .then(data => {
          console.log("Inventory updated: ", data)
          window.location.reload();
        })
        .catch(error => {
          alert()
          console.error("There was an error!", error)
        })
  }

  const [userDetails, setUserDetails] = useState({});
  const [submitClicked, setSubmitClicked] = useState(0);
  const [deviceList, setDeviceList] = useState([]);  
  

    if(submitClicked){
      
      ////console.log(deviceList)
      ////console.log(userDetails)

      //Clean and compile data for fetch
      // Check if passwords match
      if (userDetails.password === userDetails.password_validate){
        
        delete userDetails.password_validate;
        createUserFetchReq = userDetails
        createUserFetchReq.device_list = deviceList
        console.log(createUserFetchReq)

        handleAddUserClick();
      
      }
      
      else{
        console.log(userDetails.password,userDetails.password_validate)
        alert("Passwords must match!")
      }
    }
  

  return ( 
<div style = {{margin:"30px", background:"linear-gradient(to right, #005DAA, #353839)"}}>
    <div className="row">
      

      <div>
        <UserDetails 
          setUserDetails={setUserDetails}
          setSubmitClicked={setSubmitClicked}
          />
      </div>
    
    <div>
      <UserDevices 
        setDeviceList={setDeviceList}
      />
    </div>

      </div> 
    
      
  
    </div>
  );
}