import React, { useState, useEffect } from "react";
import PumpCurveSvg from "./pumpCurveSvg";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { alpha, styled } from "@mui/material/styles";
import LocalFireDepartmentRoundedIcon from "@mui/icons-material/LocalFireDepartmentRounded";
import WavesRoundedIcon from "@mui/icons-material/WavesRounded";
import { set } from "date-fns";

// import {
//   rpmCurves,
//   efficiencyCurves,
//   efficiencyRegions,
//   nonRegionCardText,
//   currentRPMCurve,
//   flowFromRPM,
//   bestEfficiencyPoint,
// } from "./resources/geometry";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#4CAF50",
    "&:hover": {
      backgroundColor: alpha("#4CAF50", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#1ED760",
  },
}));

const radioStyle = {
  color: "white",
  "&.Mui-checked": {
    color: "#1ED760",
  },
};

const PumpCurve = ({
  device,
  pressureRegisterPSI,
  flowRegisterGPM,
  motorSpeedRegisterRPM,
  tableRegisters,
  pumpModelCode,
}) => {
  const [error, setError] = useState(false);
  const [tdh, setTotalDynamicHead] = useState();
  const [cap, setCapacity] = useState();
  const [rpm, setMotorSpeed] = useState();
  const [loadingRegisterValues, setLoadingRegisterValues] = useState(true);
  const [flowMode, setFlowMode] = useState(false); //false for rpm-based, true for flow-based plotting
  console.log("PUMP MODEL CODE:", pumpModelCode);
  const [selectedRadioValue, setSelectedRadioValue] = useState(pumpModelCode);
  const [pumpGeometry, setPumpGeometry] = useState({}); // Stores dynamic geometry imports
  const [loading, setLoading] = useState(true); // Loading indicator

  const handleRadioChange = (event) => {
    setSelectedRadioValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedRadioValue === item,
    onChange: handleRadioChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleModeSwitch = (event) => {
    setFlowMode(event.target.checked);
  };

  // Dynamically load the resources based on selectedRadioValue or pumpModelCode
  useEffect(() => {
    const loadPumpResources = async () => {
      try {
        setLoading(true); // Set loading to true when the pump model changes to prevent rendering the old pump model while the new one is loading (prevents flickering)
        let pumpModule;
        if (selectedRadioValue == 0) {
          pumpModule = await import("./resources/geometryModel0");
        } else if (selectedRadioValue == 1) {
          pumpModule = await import("./resources/geometryModel1");
        }
        // Add more cases as necessary

        // Check if pumpModule is loaded correctly
        if (pumpModule) {
          setPumpGeometry(pumpModule); // Set the pumpGeometry state if data is valid
        } else {
          throw new Error("Failed to load pump module"); // Error handling for undefined module
        }
        setLoading(false); // Set loading to false when geometry is loaded
      } catch (error) {
        console.error("Error loading pump resources:", error);
        setLoading(false);
        setError(true);
      }
    };

    loadPumpResources();
    if (!pumpGeometry || !pumpGeometry.rpmCurves) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [selectedRadioValue]); // Re-run whenever pumpModelCode changes

  // Use useEffect to set the default value of the radio button based on pumpModelCode
  useEffect(() => {
    setSelectedRadioValue(pumpModelCode); // Set the default selection
  }, [pumpModelCode]); // Runs every time pumpModelCode changes

  useEffect(() => {
    const getAPIData = async () => {
      try {
        var totalDynamicHeadFeet =
          ((await getRegisterData(pressureRegisterPSI)) * 2.31) /
          (await getRegisterDetails(pressureRegisterPSI)); //convert psi to feet
        setTotalDynamicHead(totalDynamicHeadFeet);
        // console.log("TDH:", tdh);

        if (flowRegisterGPM) {
          var flowGPM =
            (await getRegisterData(flowRegisterGPM)) /
            (await getRegisterDetails(flowRegisterGPM));
          setCapacity(flowGPM);
        } else {
          setCapacity(0);
        }

        // console.log("CAP:", cap);

        //if switch is on rpm mode

        var motorSpeedRPM =
          (await getRegisterData(motorSpeedRegisterRPM)) /
          (await getRegisterDetails(motorSpeedRegisterRPM));
        setMotorSpeed(motorSpeedRPM);
        // console.log("RPM:", rpm);
      } catch (err) {
        console.log(err);
      }
    };

    getAPIData();

    async function getRegisterData(register) {
      //newest value of register of device
      return fetch(
        "/api/telemetry/dialdata?device_name=" +
          device +
          "&register=" +
          register
      ) /// ENV
        .then((resp) => {
          if (resp.status === 200) return resp.json();
          else if (resp.status === 500) {
            setLoadingRegisterValues(false);
            setError(true);
          }
        })
        .then((data) => {
          setError(false);
          if (data === undefined || data.register_value === undefined) {
            setError(true);
          } else {
            setLoadingRegisterValues(false);
            return data.register_value;
          }
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    }

    async function getRegisterDetails(register) {
      return fetch(
        "/api/telemetry/registerdetails?device_name=" +
          device +
          "&register=" +
          register
      ) /// ENV
        .then((resp) => {
          if (resp.status === 200) return resp.json();
        })
        .then((data) => {
          if (data === undefined || data.scale === undefined) {
            setError(true);
          } else return data.scale;
        })
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    }

    const interval = setInterval(() => {
      getAPIData();
    }, 3000); //refresh every 3 seconds, 1 second creates too much traffic (net::ERR_INSUFFICIENT_RESOURCES)
    return () => clearInterval(interval);
  }, []);

  console.log("TDH:", tdh);
  console.log("CAP:", cap);
  console.log("RPM:", rpm);
  console.log("FLOWMODE:", flowMode);
  console.log("LOADING REGISTERS:", loadingRegisterValues);
  console.log("ERROR:", error);

  if (
    loadingRegisterValues ||
    tdh == undefined ||
    (flowMode && cap == undefined) ||
    (!flowMode && rpm == undefined)
  )
    return (
      <>
        Gathering data <CircularProgress size="1.25rem" />
      </>
    );
  else if (error) {
    return (
      <div>
        <b>Error fetching data</b>
      </div>
    );
  } else {
    if (loading) {
      return <CircularProgress size="1.25rem" />;
    } else {
      return (
        <>
          {
            //Switch between flow-based and rpm-based plotting
          }
          <FormControlLabel
            sx={{ color: "white", fontSize: "14px" }}
            control={
              <CustomSwitch
                checked={flowMode}
                onChange={handleModeSwitch}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              flowMode
                ? "Plot based on flow sensor (motor speed curve is a calculated estimate)"
                : "Plot based on flow sensor (capacity values are calculated estimations)"
            }
          />
          {
            //Choose pump type
          }
          <br />
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="0"
                control={<Radio {...controlProps(0)} sx={radioStyle} />}
                label="PP64C10"
              />
              <FormControlLabel
                value="1"
                control={<Radio {...controlProps(1)} sx={radioStyle} />}
                label="PP66S12"
              />
            </RadioGroup>
          </FormControl>
          <div className="pump-curve">
            <PumpCurveSvg
              //plot inputs:
              device={device}
              flowMode={flowMode}
              totalDynamicHead={tdh}
              capacity={Math.round(cap * 1e2) / 1e2}
              motorSpeed={rpm}
              tableRegisters={tableRegisters}
              //pump-specific geometry:
              rpmCurves={pumpGeometry.rpmCurves}
              efficiencyCurves={pumpGeometry.efficiencyCurves}
              efficiencyRegions={pumpGeometry.efficiencyRegions}
              nonRegionCardText={pumpGeometry.nonRegionCardText}
              currentRPMCurve={pumpGeometry.currentRPMCurve}
              flowFromRPM={pumpGeometry.flowFromRPM}
              bestEfficiencyPoint={pumpGeometry.bestEfficiencyPoint}
              window={pumpGeometry.window}
            />
          </div>
        </>
      );
    }
  }
};

export default PumpCurve;
