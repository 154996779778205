export default function Unauthorized() {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        minHeight: "100vh",
        color: "white",
      }}
    >
      <h3>
        You are not authorized to view this page. Elevated account access
        required.
      </h3>
    </div>
  );
}
