import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { ac_iv } from "../components/widgets/options/mapStyles";
import CircleIcon from "@mui/icons-material/Circle";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import CircularProgress from "@mui/material/CircularProgress";

const markers = [
  "/fe_diamond_blue.png",
  "/fe_diamond_yellow.png",
  "/fe_diamond_red.png",
];
const center = { lat: 25, lng: 0 };

const mainBannerDiv = {
  width: "99%",
  minWidth: "99%",
  marginLeft: "10px",
  marginRight: "10px",
  height: "40px",
  background: "#224663",
  border: "0.1px solid #1ED760",
  borderRadius: "15px",
  marginTop: "3px",
  textAlign: "center",
  lineHeight: "2.5",
  color: "white",
  fontSize: "16px",
};

function Map() {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [statusData, setStatusData] = useState([]);
  const [lastContact, setLastContact] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [deviceData, setDeviceData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const navigateToDevicePage = (link) => {
    navigate(link);
  };

  function isDateWithinLast24Hours(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diffInHours = (now - date) / 1000 / 60 / 60;
    return diffInHours <= 24;
  }

  const fetchStatusData = (deviceName) => {
    fetch("/api/stats/latest-status?device_name=" + deviceName) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setStatusData(data);
        setLoaded(1);
      })
      .catch((err) => {
        setLoaded(0);
        console.log(err);
      });

    fetch("/api/stats/latest-contact?device_name=" + deviceName) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setLastContact(data);
      })
      .catch((err) => {
        setLoaded(0);
        console.log(err);
      });
  };

  useEffect(() => {
    fetch(
      "/api/inventory/device-status?email=" + sessionStorage.getItem("email")
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setStatusList(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));

    fetch("/api/map-data?email=" + sessionStorage.getItem("email")) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setDeviceData(data);
      })
      .catch((err) => {
        setLoaded(0);
        console.log(err);
      });
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <GoogleMap
      defaultZoom={2.6}
      defaultCenter={center}
      defaultOptions={{ styles: ac_iv }}
    >
      <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
        {deviceData.map((device) => (
          <Marker
            key={device.name}
            position={{
              lat: Number(device.latitude),
              lng: Number(device.longitude),
            }}
            onClick={() => {
              //handleMarkerClick(device)
              setSelectedDevice(device);
              fetchStatusData(device.name);
            }}
            //                          STATUS SPECIFIC ICONS
            // icon={{
            //   url: ,
            // }}
            icon={{
              url:
                statusList[device.name].last_contact &&
                isDateWithinLast24Hours(statusList[device.name].last_contact)
                  ? "/fe_diamond_blue.png"
                  : "/fe_diamond_red.png",
            }}
          />
        ))}

        {selectedDevice && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedDevice(null);
            }}
            position={{
              lat: Number(selectedDevice.latitude),
              lng: Number(selectedDevice.longitude),
            }}
          >
            <div>
              {
                //STATUS INDICATOR
                /* <h2><CircleIcon style={{ color: selectedDevice.status=="good"?'#4BB543':selectedDevice.status=="ok"?'#EED202':'#FF0000', fontSize: "small" }}/> {selectedDevice.name}</h2>
                      <p>{"Status: "+selectedDevice.status}</p> */
              }
              <h2>{selectedDevice.name}</h2>
              <br />
              {loaded ? (
                <>
                  {statusData.map((tile, index) => (
                    <div>
                      <b>{tile.name + ": "}</b>
                      {tile.value +
                        " (" +
                        new Date(tile.time_stamp).toLocaleString() +
                        ")"}
                    </div>
                  ))}
                </>
              ) : (
                <div>
                  Loading...
                  <CircularProgress size="1rem" />
                </div>
              )}
              <Button
                sx={{
                  borderRadius: "100px",
                  backgroundColor: "none",
                  ":hover": { backgroundColor: "#CEF7F7" },
                }}
                onClick={() =>
                  navigateToDevicePage(
                    "/device-dash?device_name=" + selectedDevice.name
                  )
                }
              >
                View Details
              </Button>
            </div>
          </InfoWindow>
        )}
      </MarkerClusterer>
    </GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default function MapView() {
  return (
    <>
      <div style={mainBannerDiv}>Map</div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%", height: "83vh", marginTop: "6px" }}>
          <WrappedMap
            googleMapURL={
              "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAcD7JACl_oYIw-9IBIoC5LThQBJvm6Fhg&callback=Function.prototype"
            }
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
          />
        </div>
      </div>
    </>
  );
}
