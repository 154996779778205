import React from "react";
import '../css/popUp.css'
import Modal from "react-modal";
import Speedometer from './widgets/speedometer'
import UserDevicesSpecific from './userDevicesSpecific'


Modal.setAppElement("#root");

const modalStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      overflow              : 'visibile',
      width                 : '70%',
      height                : '75%',
      backgroundColor       : "#E5FFFD",
      border                : "none",
      boxShadow: '4px 8px 36px #005DAA',
    }
  };
const buttonStyles = {
  position: "absolute",
  top: "10px",
  right: "10px"
};

const divStyles = {
    title:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '5vh',    
        fontSize: 26,
        color: '#555555'
    },
    subtitle:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '15vh',    
        fontSize: 20,
        color: '#555555',
        textAlign: 'center'
    },
    select:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '10vh',    
        fontSize: 20,
        color: '#555555',
        textAlign: 'center'
    },
    confirmation:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh',    
        fontSize: 20,
        color: '#555555',
        textAlign: 'center'
    }
}

export default function FirstTimePopUp({setFirstTime, firstTime}) {

    //store user prefernces
    const [homeDevices, setHomeDevices] = React.useState([]);
    
    //Update User DB with selected preferences
    const savePreferences = () => {
        console.log(homeDevices)

      const options = {
    
        method: 'POST',
        headers: {
          "Content-Type":"application/json"
        },
        body: JSON.stringify({
            'email': sessionStorage.getItem('email'),
            'home_preferences': homeDevices
        })
      }
  
      fetch('/api/user/sethomeprefs', options) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
        .then(resp =>{
          if(resp.status === 200) return resp.json(); 
          else (alert("Error"))
        })
        .then(data => {
          console.log("User preferences updated: ", data)
        })
        .catch(error => {
          alert()
          console.error("There was an error!", error)
        })


    }
 
    const handleClose = () => {
        sessionStorage.setItem("first_time", "false")
        
        savePreferences()
        setFirstTime(false)
        
    }
  return (
    <div className="App">

      <Modal
        isOpen={firstTime}
        onRequestClose={handleClose}
        contentLabel={"First-time User Setup"}
        style={modalStyles}
        closeTimeoutMS={500}
      >
        <div style={divStyles.title}>   Welcome, {sessionStorage.getItem('first_name')} !    </div>
        <div style={divStyles.subtitle}>   Create your personalised dashboard here. <br/>The devices you select below will be available on your home page whenever you login.    </div>
        
        <div style={divStyles.select}>  
            <UserDevicesSpecific setHomeDevices={setHomeDevices}/>                  
        </div>
        <div style={divStyles.confirmation}>  
            <div className="submit">
                    <button
                        type="button"
                        onClick={handleClose}
                        >
                            <span>Confirm Preferences</span>
                    </button> 
            </div>   
        </div>
        

        
                                
                            
        
        
        <button onClick={handleClose} style={buttonStyles}>X</button>
        
        
      </Modal>
    </div>
  );
}
