//The options used to plot chart depending on view chosen by user ("time-window" or "live plot")
//The only difference between the two options (so far) is the range of the x-axis.

var max = new Date().getTime()  // Current timestamp
var min = new Date().getTime() - 86400 // timestamp 90 days before
var range = max-min

const windowUrl = window.location.search;
const params = new URLSearchParams(windowUrl);
var deviceName = params.get('device_name')

export var liveOption = {
  chart: {
    id: 'live',
    events: {                                                                   //events 
      beforeZoom: function(ctx) {
        // we need to clear the range as we only need it on the iniital load.
        ctx.w.config.xaxis.range = undefined
      }
    },
    animations: {
      enabled: false,
      easing: 'easeinout',
      speed: 800,
      animateGradually: {
          enabled: true,
          delay: 150
      },
      dynamicAnimation: {
          enabled: true,
          speed: 350
      }
  },
    background: '#f5fbff',
    type: 'scatter',
    stacked: false,
    height: 350,
    zoom: {
      type: 'x',
      enabled: true,
      autoScaleYaxis: true
    },
    toolbar: {
      autoSelected: 'zoom',
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ',',
          headerCategory: 'time',
          headerValue: deviceName,
          dateFormatter(timestamp) {
            return timestamp
          }
        }
      }

    }
  },
  dataLabels: {
    enabled: false
  },
  markers: {
    size: 0,
  },

  fill: {
    type: 'solid',
    pattern: {
      style: 'squares',
      width: 0.01,
      height: 0.01,
      strokeWidth: 0.01,
    },
  },
  yaxis: {
    
    labels: {
      formatter: function (y) {
        if(typeof y !== "undefined") {
          return  y.toFixed(0);
        }
        return y;
        
      },
    },
    title: {
      text: 'Value'
    },
  },
  xaxis: {
    labels: {
      datetimeUTC: false
    },
    type: 'datetime',
    range: range 
  },
  tooltip: {
    shared: false,
    x: {
      show: true,
      format: 'MM/dd/yy HH:mm:ss',
      formatter: undefined,
  },
    y: {
      formatter: function (y) {
        if(typeof y !== "undefined") {
          return  y.toFixed(0);
        }
        return y;
        
      }
    }
  },

  markers: {
      size: 0,
  },

  stroke: {
    show: true,
    curve: 'stepline',
    lineCap: 'butt',
    width: 1,
    dashArray: 0,      
}
};



export var defaultOption = {
    chart: {
      id: 'default',
      events: {                                                                   //events 
        beforeZoom: function(ctx) {
          // we need to clear the range as we only need it on the iniital load.
          ctx.w.config.xaxis.range = undefined
        }
      },
      animations: {
        enabled: false,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
            enabled: true,
            delay: 150
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
    },
      background: '#f5fbff',
      type: 'scatter',
      stacked: false,
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom',
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'date,time',
            headerValue: deviceName,
            dateFormatter(timestamp) {
              return timestamp
            }
          }
        }

      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
    },

    fill: {
      type: 'solid',
      pattern: {
        
        width: 0.01,
        height: 0.01,
        strokeWidth: 0.01,
      },
    },
    yaxis: {
      
      labels: {
        formatter: function (y) {
          if(typeof y !== "undefined") {
            return  y.toFixed(0);
          }
          return y;
          
        },
      },
      title: {
        text: 'Value'
      },
    },
    xaxis: {
      labels: {
        datetimeUTC: false
      },
      type: 'datetime',
      //range: use default range
    },
    tooltip: {
      shared: false,
      x: {
        show: true,
        format: 'MM/dd/yy HH:mm:ss',
        formatter: undefined,
    },
      y: {
        formatter: function (y) {
          if(typeof y !== "undefined") {
            return  y.toFixed(0);
          }
          return y;
          
        }
      }
    },

    markers: {
        size: 0,
    },

    stroke: {
      show: true,
      curve: 'stepline',
      lineCap: 'butt',
      width: 1,
      dashArray: 0,      
  }
};
