import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Thermometer from "react-thermometer-component";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  meter: {
    marginTop: "10%",
    display: "flex",
    padding: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "12pt",
    color: "#ffff",
  },
};

//The name "thermalmeter" was chosen not out of incompetence but to avoid clashing of names.
const Thermalmeter = ({ id, register, device }) => {
  const [details, setDetails] = useState({});
  const [error, setError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [dialValue, setDialValue] = useState(0);
  const [loadingRegisterDetails, setLoadingRegisterDetails] = useState(true);
  const [loadingRegisterValues, setLoadingRegisterValues] = useState(true);

  useEffect(() => {
    fetch(
      "/api/telemetry/registerdetails?device_name=" +
        device +
        "&register=" +
        register
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) {
          setLoadingRegisterDetails(false);
          return resp.json();
        } else if (resp.status === 500) {
          setLoadingRegisterValues(false);
          setLoadingRegisterDetails(false);
          setError(true);
        }
      })
      .then((data) => {
        setDetails(data);
        if (
          data === undefined ||
          data.name === undefined ||
          data.unit === undefined ||
          data.scale === undefined ||
          data.min === undefined ||
          data.max === undefined
        ) {
          setError(true);
        } else setLoadingRegisterDetails(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }, []);

  const getRegisterData = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    device = params.get("device_name");

    //newest value of register of device
    fetch(
      "/api/telemetry/dialdata?device_name=" + device + "&register=" + register
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) {
          setLoadingRegisterValues(false);
          return resp.json();
        } else {
          setError(true);
        }
      })
      .then((data) => {
        setDialValue(data.register_value);
        //console.log("Thermal data fetch",dialValue)
        setError(false);
        if (data === undefined || data.register_value === undefined) {
          setError(true);
        } else setLoadingRegisterValues(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  useEffect(() => {
    const getAPIData = async () => {
      try {
        await getRegisterData(device);
      } catch (err) {
        console.log(err);
      }
    };

    getAPIData();

    const interval = setInterval(() => {
      //device = searchParams.get("device_name")
      //console.log(device)

      getAPIData();
    }, 25000);
    return () => clearInterval(interval);
  }, []);

  if (loadingRegisterDetails || loadingRegisterValues)
    return <CircularProgress size="1.25rem" />;
  else {
    return (
      <>
        <div style={styles.meter}>
          {error ? (
            <div style={styles.title}>No data available</div>
          ) : (
            <>
              <Thermometer
                theme="dark"
                value={dialValue / details.scale}
                max={120}
                steps="3"
                format="°C"
                size="small"
              />
            </>
          )}
        </div>
        {error ? (
          <div style={styles.title}>Check IRIS configuration</div>
        ) : (
          <div style={styles.title}>
            {details.name + " (" + details.unit + ")"}
          </div>
        )}
        <br />
      </>
    );
  }
};

export default Thermalmeter;
