import React from "react";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { set } from "date-fns";
import logo1 from "../../assets/herc_logo_1.svg";
import logo2 from "../../assets/sunbelt_logo_2.svg";
import logo3 from "../../assets/CKC_logo_3.jpeg";

// Function to get the background color based on the tile content
const getBackgroundColor = (tile) => {
  let color;
  if ("color" in tile) {
    if (tile.color === "red") {
      color = "red";
    } else if (tile.color === "green") {
      color = "green";
    } else if (tile.color === "yellow") {
      color = "#FFBF00";
    } else {
      color = "#224663"; // default color
    }
  } else {
    color = "#224663"; // default color
  }

  return {
    ...statsDiv,
    background: `linear-gradient(to top, ${color}, #353839)`,
  };
};

const statsDiv = {
  position: "relative",
  width: "22.5%",
  minWidth: "202.27px",
  // height: "100px",
  minHeight: "100px",
  display: "block",
  overflow: "auto",
  background: "linear-gradient(to top, #224663, #353839)",
  border: "0.5px solid #005DAA",
  borderRadius: "5px",
  marginTop: "10px",
  marginBottom: "10px",
  textAlign: "center",
  color: "white",
  fontSize: "16px",
  padding: "20px",
};

const logoDiv = {
  position: "relative",
  width: "22.5%",
  minWidth: "202.27px",
  height: "100px",
  background: "linear-gradient(to top, #ffffff, #353839)",
  border: "0.5px solid #005DAA",
  borderRadius: "5px",
  marginTop: "10px",
  marginBottom: "10px",
  textAlign: "center",
  color: "white",
  fontSize: "16px",
  padding: "2px",
};
const refreshDiv = {
  position: "absolute",
  top: "1px",
  right: "0px",
};

export default function StatusRibbon(deviceName) {
  const [statusTiles, setStatusTiles] = useState([]);
  const [lastContact, setLastContact] = useState("");
  const [orgId, setOrgId] = useState(0);
  const [logoImg, setLogoImg] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getAPIData = async () => {
      try {
        await fetchStatusData();
      } catch (err) {
        console.log(err);
      }
    };

    getAPIData();

    const interval = setInterval(() => {
      getAPIData();
    }, 3000);
    return () => clearInterval(interval);
  }, [deviceName]);

  const fetchStatusData = () => {
    fetch("/api/stats/latest-status?device_name=" + deviceName.device) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setStatusTiles(data);
        setLoaded(1);
      })
      .catch((err) => {
        setLoaded(0);
        console.log(err);
      });

    fetch("/api/stats/latest-contact?device_name=" + deviceName.device) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        const date = new Date(data.last_contact);
        setLastContact(date.toLocaleString());
      })
      .catch((err) => {
        setLoaded(0);
        console.log(err);
      });
    fetch("/api/inventory/org-id?device_name=" + deviceName.device) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setOrgId(data.org_id);
        switch (data.org_id) {
          case 1:
            setLogoImg(logo1);
            break;
          case 2:
            setLogoImg(logo2);
            break;
          case 3:
            setLogoImg(logo3);
            break;
          default:
            break;
        }
        setLoaded(1);
      })
      .catch((err) => {
        setLoaded(0);
        console.log(err);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {orgId == 0 ? null : (
        <div style={{ ...logoDiv, width: "auto", height: "auto" }}>
          <img
            src={logoImg}
            alt="logo"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </div>
      )}
      {loaded && statusTiles ? (
        <>
          {statusTiles.map((tile, index) => (
            <div key={index} style={getBackgroundColor(tile)}>
              {tile.name}

              {tile.register == "ML" ? (
                //If ML and 0:
                tile.value == "Normal Operation" ? (
                  <div
                    style={{
                      color: "#1ED760",
                      fontSize: "22px",
                      fontWeight: "bolder",
                      margin: "10px",
                    }}
                  >
                    {tile.value}
                  </div>
                ) : (
                  //If ML and NOT 0:
                  <div
                    style={{
                      color: "red",
                      fontSize: "22px",
                      fontWeight: "bolder",
                      margin: "10px",
                    }}
                  >
                    {tile.value}
                  </div>
                )
              ) : (
                //If NOT ML
                <div
                  style={{
                    fontSize: "vmax",
                    fontWeight: "bolder",
                    margin: "5px",
                  }}
                >
                  {tile.value}
                </div>
              )}

              <div style={{ fontSize: "11px" }}>
                ({new Date(tile.time_stamp).toLocaleString()})
              </div>
              <div style={refreshDiv}></div>
            </div>
          ))}
        </>
      ) : (
        <div style={{ fontSize: "22px", fontWeight: "bolder", margin: "10px" }}>
          Loading...
        </div>
      )}

      <div style={statsDiv}>
        Last Connected:
        <div style={{ fontSize: "18px", fontWeight: "bolder", margin: "10px" }}>
          {lastContact}
        </div>
      </div>
    </div>
  );
}
