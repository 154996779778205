import React, { useState } from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

const CustomButton = styled(Button)({
  padding: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "30px",
  boxSizing: "border-box",
  borderRadius: 20,
  background: "#0093f2",
  color: "#ffffff",
  fontWeight: "Condensed Heavy",
  fontSize: "100%",
  transform: "none",
  boxShadow: "none",
  transition: "background 1.0s,border-color .2s,color 0.2s",
  "&:hover": {
    backgroundColor: "green",
  },
});

export default function FileUploadTool() {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [configDescription, setConfigDescription] = useState("");

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append("file", selectedFile);
    formData.append("description", configDescription);

    fetch("/api/configs/add", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          setIsSelected(false);
          return response.json();
        }
      })
      .then((result) => {
        console.log("Success:", result);
        alert("Successfully uploaded");
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Upload failed!");
      });
  };

  return (
    <div>
      {isSelected ? (
        <div>
          <p>
            <b>Filename:</b> {selectedFile.name}
          </p>
          <p>
            <b>File Type:</b> {selectedFile.type}
          </p>
          <p>
            <b>Size:</b> {selectedFile.size / 1000} KB, <b>Last Modified:</b>{" "}
            {selectedFile.lastModifiedDate.toLocaleDateString()}
          </p>

          <Box
            sx={{
              my: 1,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box noValidate>
              <TextField
                sx={{ width: 400, backgroundColor: "white" }}
                margin="normal"
                id="desc"
                label="Add Config Description"
                name="desc"
                autoComplete="desc"
                autoFocus
                value={configDescription}
                onChange={(e) => setConfigDescription(e.target.value)}
                InputLabelProps={{
                  style: { backgroundColor: "#fff", borderRadius: "20px" },
                }}
              />
            </Box>
          </Box>
          <div style={{ disply: "flex", flexDirection: "row" }}>
            <CustomButton
              variant="contained"
              component="label"
              onClick={handleSubmission}
            >
              Confirm Upload
            </CustomButton>
          </div>
        </div>
      ) : (
        <>
          <div style={{ color: "#C0C0C0", fontWeight: "bold" }}>
            Select and deploy configurations
          </div>
          <br />
          <CustomButton variant="contained" component="label">
            Upload new JSON configuration
            <input type="file" hidden name="file" onChange={changeHandler} />
          </CustomButton>
        </>
      )}
    </div>
  );
}
