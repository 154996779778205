//This component is responsible for fetching and rendering select bars for different device types based on user access.

import { useEffect, useState } from "react";
import Select from "react-select";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneTextField from "mui-phone-textfield";
import NotificationButton from "./createNotificationButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";

var registerOptions = [];
var logicOptions = [
  { label: "greater than ( > )", value: ">" },
  { label: "less than ( < )", value: "<" },
  { label: "equals ( == )", value: "==" },
  { label: "does not equal ( =/= )", value: "!=" },
];
const registerDetailDiv = {
  padding: "5px",
  backgroundColor: "rgba(53, 57, 53, 0.6)",
  color: "white",
};

export default function NotificationCreator({
  selectedDevices,
  setSelectedCommand,
  disableControl,
}) {
  const [registerInput, setRegisterInput] = useState([]);
  const [logicInput, setLogicInput] = useState(null);
  const [thresholdInput, setThresholdInput] = useState("");
  const [registerDetails, setRegisterDetails] = useState({});
  const [notificationTitleInput, setNotificationTitleInput] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(); // The PhoneNumber instance.
  const [value, setValue] = useState(""); // The phone input value.
  const [country, setCountry] = useState("US"); // The selected country.
  const [comply, setComply] = useState(false);

  console.log(registerInput.length);

  const onPhoneChange = ({ formattedValue, phoneNumber }) => {
    setValue(formattedValue);
    setPhoneNumber(phoneNumber);
  };

  const onCountrySelect = ({ country, formattedValue, phoneNumber }) => {
    setValue(formattedValue);
    setCountry(country);
    setPhoneNumber(phoneNumber);
  };

  useEffect(() => {
    fetch("/api/inventory/registerlist?device_name=" + selectedDevices[0]) // all devices are the same type, we send the first on the list to get the registeres to populate react-select
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            registerOptions.push({ label: data[key], value: key });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedDevices]);

  useEffect(() => {
    if (registerInput.length != 0) {
      fetch(
        "/api/telemetry/registerdetails?device_name=" +
          selectedDevices[0] +
          "&register=" +
          registerInput.value
      ) // all devices are the same type, we send the first on the list to get the registeres to populate react-select
        .then((resp) => {
          if (resp.status === 200) return resp.json();
        })
        .then((data) => {
          setRegisterDetails(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [registerInput]);

  //Persist created notification
  const noteButtonClicked = () => {
    var opts = {};
    try {
      opts = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device_list: selectedDevices,
          notification_name: notificationTitleInput,
          register_address: registerInput.value,
          condition: logicInput.value,
          threshold: (
            parseInt(thresholdInput) * parseInt(registerDetails.scale)
          ).toString(),
          phone: phoneNumber.number,
          email: sessionStorage.getItem("email"),
        }),
      };
    } catch (error) {
      alert(error);
      return;
    }

    fetch("/api/notifications/create", opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        else if (resp.status === 401) console.error("401 error");
      })
      .then((data) => {
        console.log("New Notification MongoID:", data);

        setRegisterInput([]);
        setLogicInput(null);
        setThresholdInput("");
        setRegisterDetails({});
        setNotificationTitleInput("");
        setPhoneNumber();
        setValue("");
        setCountry("US");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleRegisterInput = (obj) => {
    setRegisterInput(obj);
  };

  const handleLogicInput = (obj) => {
    setLogicInput(obj);
  };

  //styles for selected options
  const customStyles = {
    // For the select it self, not the options of the select
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        width: "80%",
        marginBottom: "20px",
        cursor: isDisabled ? "not-allowed" : "default",
        pointerEvents: "all",
        backgroundColor: isDisabled ? "rgba(206, 217, 224, 0.2)" : "white",
        color: isDisabled ? "white" : "white",
      };
    },
    // For the options
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "default",
        color: "black",
      };
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "6px",
        color: "black",
        textAlign: "left",
        height: "auto",
        overflow: "visible",
      }}
    >
      {
        //////INPUT PANEL
      }
      <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
        <br />
        <div style={{ color: "#C0C0C0", minWidth: "7vh" }}>
          Create a notification trigger. Notification is triggered if condition
          specified is evaluated to be <b>true</b>.
        </div>
        <br />

        <Select
          menuPosition="absolute"
          styles={customStyles}
          margin="normal"
          options={registerOptions}
          placeholder="Select Register"
          value={registerInput}
          onChange={(e) => handleRegisterInput(e)}
          isDisabled={disableControl}
        />
        {registerDetails && (
          <>
            <Select
              menuPosition="absolute"
              styles={customStyles}
              margin="normal"
              options={logicOptions}
              placeholder="Condition"
              value={logicInput}
              onChange={(e) => handleLogicInput(e)}
              isDisabled={disableControl}
            />
          </>
        )}

        {logicInput && (
          <TextField
            type="number"
            sx={{
              backgroundColor: "white",
              zIndex: 0,
              width: "80%",
              textAlign: "center",
              marginBottom: "20px",
            }}
            id="args"
            label="Threshold value"
            name="args"
            autoComplete="args"
            autoFocus
            value={thresholdInput}
            onChange={(e) => setThresholdInput(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {registerDetails.unit}
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { backgroundColor: "#fff", borderRadius: "10px" },
            }}
          />
        )}

        {thresholdInput && (
          <>
            <TextField
              sx={{
                backgroundColor: "white",
                zIndex: 0,
                width: "80%",
                textAlign: "center",
                marginBottom: "20px",
              }}
              id="args"
              label="Notification Title"
              name="args"
              autoComplete="args"
              value={notificationTitleInput}
              onChange={(e) => setNotificationTitleInput(e.target.value)}
              InputLabelProps={{
                style: { backgroundColor: "#fff", borderRadius: "10px" },
              }}
            />
            <PhoneTextField
              sx={{
                backgroundColor: "white",
                zIndex: 0,
                width: "80%",
                textAlign: "center",
                marginBottom: "20px",
              }}
              label="Phone"
              error={Boolean(value && phoneNumber?.country !== country)}
              value={value}
              country={country}
              onCountrySelect={onCountrySelect}
              onChange={onPhoneChange}
              InputLabelProps={{
                style: { backgroundColor: "#fff", borderRadius: "10px" },
              }}
            />
            <div style={{ color: "#C0C0C0", minWidth: "7vh" }}>
              Note: A notification will also be sent to your registered email
              address.
            </div>
            <br />
            <FormControlLabel
              sx={{ color: "white" }}
              required
              control={
                <Checkbox
                  checked={comply}
                  onChange={(e) => {
                    console.log("target checked? - ", e.target.checked);
                    setComply(e.target.checked);
                  }}
                  sx={{
                    [`&, &.${checkboxClasses.checked}`]: {
                      color: "white",
                    },
                  }}
                />
              }
              label="By entering my mobile number and checking this box, I agree to receive SMS alerts from Franklin Electric. Message frequency varies by system conditions. Message and data rates may apply. Reply STOP to unsubscribe. View terms and privacy policy at https://franklin-electric.com/policies#privacy"
            />
            {comply && <NotificationButton handleClick={noteButtonClicked} />}
          </>
        )}
      </div>

      {
        //////GUIDELINE PANEL
      }
      <div
        style={{
          width: "30%",
          marginLeft: "10%",
          paddingLeft: "5%",
          marginRight: "0.5%",
          borderLeft: "0.5px solid #005DAA",
        }}
      >
        <br />
        <div style={{ color: "#C0C0C0", height: "7vh" }}>
          <b>Guidelines</b> for notification creation
        </div>
        <div style={registerDetailDiv}>
          {registerInput.length == 0 ? (
            <div style={{ color: "red" }}> ✖ Select a register to monitor</div>
          ) : (
            <>
              <div style={{ color: "lightgreen" }}>✓ Register selected:</div>
              <br />
              {Object.entries(registerDetails).map(([key, value], i) => (
                <div style={{ color: "lightgreen" }} key={i}>
                  {key} : {value}
                </div>
              ))}
            </>
          )}
        </div>
        <br />
        <div style={registerDetailDiv}>
          {logicInput == null ? (
            <div style={{ color: "red" }}>
              ✖ Create a condition. Notification is dispatched if threshold
              value meets condition.
            </div>
          ) : (
            <div style={{ color: "lightgreen" }}>
              ✓ Notification will be triggered if monitored value is{" "}
              {logicInput.label} threshold value
            </div>
          )}
        </div>
        <br />
        <div style={registerDetailDiv}>
          {thresholdInput == "" ? (
            <div style={{ color: "red" }}>✖ Enter threshold value</div>
          ) : (
            <>
              <div style={{ color: "lightgreen" }}>
                ✓ Notification will be triggered if,
                <br />
                <br />{" "}
                <b>
                  {registerDetails.name} {logicInput.value} {thresholdInput}{" "}
                </b>{" "}
                is reported by the following device(s):
                {selectedDevices.map((devName) => (
                  <div>{devName}</div>
                ))}
              </div>
              <br />
              <div style={{ color: "#C0C0C0" }}>
                <b>
                  Lastly, give the notification a name and provide contact
                  details to complete process.
                </b>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
