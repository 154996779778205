import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function PumpInfoWindow({
  device,
  flowMode,
  totalDynamicHead,
  capacity,
  motorSpeed,
  tableRegisterList,
}) {
  const tile = {
    // height: "937.6px",
    position: "relative",
    width: "30%",
    minWidth: "400px",
    background: "none",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    marginTop: "17px",
    marginBottom: "10px",
    marginLeft: "0.10%",
    marginRight: "15%",
    textAlign: "left",
    color: "white",
    fontSize: "13px",
    padding: "5px",
    display: "flex",
    overflow: "hidden",
    flexWrap: "nowrap",
  };

  const subTile = {
    top: "0",
    width: "28%",
    right: "0",
    bottom: "0",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    // background: "green",
    position: "absolute",
    textAlign: "center",
    verticalAlign: "middle",
    paddingTop: "5%",
    fontSize: "vmax",
    fontWeight: "bold",
  };

  const [dischargePressureText, setDischargePressureText] = useState(
    <CircularProgress size="1.25rem" />
  );
  const [flowRateText, setFlowRateText] = useState(
    <CircularProgress size="1.25rem" />
  );
  const [npsHaText, setNpsHaText] = useState(
    <CircularProgress size="1.25rem" />
  );
  const [npsHrText, setNpsHrText] = useState(
    <CircularProgress size="1.25rem" />
  );
  const [motorSpeedText, setMotorSpeedText] = useState(
    <CircularProgress size="1.25rem" />
  );
  const [tableRegisters, setTableRegisters] = useState({});

  useEffect(() => {
    if (totalDynamicHead === undefined) {
      setDischargePressureText(<CircularProgress size="1.25rem" />);
    } else {
      setDischargePressureText(totalDynamicHead);
    }

    if (capacity === undefined) {
      setFlowRateText(<CircularProgress size="1.25rem" />);
    } else {
      setFlowRateText(capacity);
    }

    if (motorSpeed === undefined) {
      setMotorSpeedText(<CircularProgress size="1.25rem" />);
    } else {
      setMotorSpeedText(motorSpeed);
    }
  }, [flowMode, totalDynamicHead, capacity, motorSpeed]);

  useEffect(() => {
    console.log("Table Register List:", tableRegisterList);
    if (tableRegisterList) {
      tableRegisterList.forEach((register) => {
        var tableRegistersObject = tableRegisters;
        fetch(
          "/api/telemetry/registerdetails?device_name=" +
            device +
            "&register=" +
            register
        )
          .then((resp) => {
            if (resp.status === 200) return resp.json();
          })
          .then((data) => {
            setTableRegisters(
              (tableRegistersObject[register] = {
                register: register,
                name: data.name,
                scale: data.scale,
                unit: data.unit,
                min: data.min,
                max: data.max,
                value: <CircularProgress size="1.25rem" />,
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }, []);

  const getRegisterData = (register) => {
    var tableRegistersObject = tableRegisters;
    //newest value of register of device
    fetch(
      "/api/telemetry/dialdata?device_name=" + device + "&register=" + register
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        tableRegistersObject[register]["value"] = data.register_value;
        setTableRegisters(tableRegistersObject);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const getAPIData = async () => {
      try {
        if (tableRegisterList) {
          tableRegisterList.forEach((register) => {
            getRegisterData(register);
          });
        }

        // await getRegisterData(device);
      } catch (err) {
        console.log(err);
      }
    };

    getAPIData();

    const interval = setInterval(() => {
      //device = searchParams.get("device_name")
      //console.log(device)
      console.log("Register Object:", tableRegisters);
      getAPIData();
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  console.log("Infowindow TDH:", totalDynamicHead);
  console.log("Infowindow Table Registers:", tableRegisters);

  return (
    <>
      <div style={tile}>
        <p>Discharge Pressure (feet of water head)</p>
        <div style={subTile}>{dischargePressureText}</div>
      </div>
      <div style={tile}>
        <p>Flow Rate (GPM)</p>
        <div style={subTile}>{flowRateText}</div>
      </div>

      <div style={tile}>
        <p>Motor Speed (RPM)</p>
        <div style={subTile}>{motorSpeedText}</div>
      </div>
      {/* <div style={tile}>
        <p>Net Positive Suction Head Available (NPSHa)</p>
        <div style={subTile}>?!?!?!</div>
      </div>
      <div style={tile}>
        <p>Net Positive Suction Head Required (NPSHr)</p>
        <div style={subTile}>?!?!?!</div>
      </div>
      <div style={tile}>
        <p>Output Current (Amps)</p>
        <div style={subTile}>24</div>
      </div>
      <div style={tile}>
        <p>Output Power (kW)</p>
        <div style={subTile}>24</div>
      </div> */}
      {}
    </>
  );
}
