import { MenuGenerator } from "./menuItems";
import MenuItems from "./subMenuSupport";
import logo from '../../assets/logo.png'
import '../../css/Nav.css'

const Navbar = () => {
  const menuData = MenuGenerator()
    return (
      <header>
        <div className="nav-area">
                    <a href="/#" className="logo">
                    <img src={logo}/>
                    </a>

                    <nav>
                    <ul className="menus">
                    
                    {menuData.map((menu, index) => {
                    const depthLevel = 0;
                    return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
                    })}
        </ul>
      </nav>
        </div>
        
      </header>
      
    );
  };
  
  export default Navbar;