//This component is responsible for fetching and rendering select bars for different device types based on user access.

import { useEffect, useState} from 'react';
import Select from 'react-select';
import Button from '@mui/material/Button';
import { styled } from '@mui/styles';

var configOptions = []
var configInput = []


const CustomButton = styled(Button)({
    
    padding: "15px", 
    
    marginRight:"0px",
    marginTop:"15px",
    marginBottom:"2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
    boxSizing: "border-box",    
    borderRadius: 10, 
    background: "#0093f2",
    color: "#ffffff",
    fontWeight: "Condensed Heavy",
    fontSize: "100%",
    transform: "none",
    boxShadow: "none",
    transition: "background 1.0s,border-color .2s,color 0.2s",
    "&:hover": {
        backgroundColor:  "green"
    }
});



export default function ConfigSelectDeploy({selectedDevices, disableControl}) {

    const [selectedConfig, setSelectedConfig] = useState(null)
    const [isSelected, setIsSelected] = useState(false);

    const handleDeployClick = () => { 
        
        const opts = {
    
            method: 'POST',
            headers: {
              "Content-Type":"application/json"
            },
            body: JSON.stringify({
              "device_list": selectedDevices.join(','),
              "config": selectedConfig
            })
          }
          fetch('/api/control/config', opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
          .then(resp =>{
            if(resp.status === 200) return resp.json(); 
            else if (resp.status === 401) (console.error("401 error"))
          })
          .then(data => {
            console.log("Publish Result:", data)
            setSelectedConfig(null)
            configInput=[]
          })
          .catch(error => {
            console.error("There was an error!", error)
          })  
    
    
    }

    useEffect(() => {
        
        fetch("/api/configs/list") /// ENV
        .then(resp =>{
          if(resp.status === 200) return resp.json();
        })
        .then(data => {
            
            data.forEach((configuration) =>{
                configOptions.push({"label":configuration.name+" - "+configuration.description, "value":configuration.name})
            })

            configOptions = [...new Set(configOptions)];
        })
        .catch((err) => {
          console.log(err)
        });
      
    }, [])

    const handleChange = (obj) => {
        configInput = obj
        setSelectedConfig(configInput.value)
        setIsSelected(true)
        
    }

    //styles for selected options
    const customStyles = {
      // For the select it self, not the options of the select
      control: (styles, { isDisabled}) => {
        return {
          ...styles,
          cursor: isDisabled ? 'not-allowed' : 'default',
          pointerEvents: 'all',
          backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.2)' : 'white',
          color: isDisabled ? 'white' : 'white'
        }
      },
      // For the options
      option: (styles, { isDisabled}) => {
        
        return {
          ...styles,
          cursor: isDisabled ? 'not-allowed' : 'default',
          color:"black"
        };
      },
    };

    return(
        <div style={{width:"50%", marginLeft:"25%"}}>
          <div style={{color:"#C0C0C0", fontWeight:"bold"}}>
            Select and deploy configurations
          </div>
          <br/>
          <Select
              styles = {customStyles}
              margin = "normal"            
              options={configOptions}  
              placeholder = "Select Configuration"
              value={configInput}
              onChange={(e) => handleChange(e)}
              isDisabled = {disableControl}
          />

          {isSelected?(
            <CustomButton 
              variant="contained" 
              component="label"
              onClick={handleDeployClick}
              >
              Deploy Configuration
            </CustomButton>
          ):("")}
        </div>
        
)
}