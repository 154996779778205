
import {useEffect, useState} from 'react'

export function MenuGenerator(){
    //var myDevicesSubmenu = []
    const [myDevicesSubmenu, setMyDevicesSubmenu] = useState([])
    //Populate menu with options to select from (data fetched from Inventory).
    useEffect(() => {
        
        fetch("/api/inventory/mydevices-categorized?email="+sessionStorage.getItem("email")) /// ENV
        .then(resp =>{
          if(resp.status === 200) return resp.json(); 
        })
        .then(data => {

            setMyDevicesSubmenu(data)
        })
        .catch((err) => {
          console.log(err)
        });
      
    }, [])
    
    const menuItems = [
        {
            title: "Home",
            to: "/landing"
        },
        {
            title: "My Devices",
            submenu: myDevicesSubmenu
        },
        
        {
            title: "Notifications",
            to: "/notifications"
        },
        
        {
            title: "Map",
            to: "/map"
        },
        
        {
            title: "Remote Control",
            to: "/remote-control"
        },

        {
            title: "IRIS Management",
            to: "/iris-management"
        },
        
        {
            title: "Admin",
            to: "/admin",
            submenu: [
                {
                 title: "User Management",
                 to: "/1",
                 submenu: [
                    {
                     title: "Create User",
                     to: "/createuser"
                    },
                    {
                     title: "Remove User",
                     to: "/removeuser"
                    },
                    {
                     title: "Edit User Roles",
                     to: "/edituser"
                    },
                    {
                        title: "Reset a Password",
                        to: "/passwordreset"
                    }
                ]
                },
                {
                 title: "Device Management",
                 to: "/2",
                 submenu: [
                    {
                     title: "Add Device",
                     to: "/adddevice"
                    },
                    {
                     title: "Remove Device",
                     to: "/removedevice"
                    }
                 ]
                }
            ]
        },
    
        {
            title: "Logout",
            to: "/logout"
    
        }
        
       ];

       return menuItems;
}