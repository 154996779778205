//This component is responsible for fetching and rendering select bars for different device types based on user access.

import { useEffect, useState } from "react";
import Select from "react-select";
import Button from "@mui/material/Button";
import { styled } from "@mui/styles";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";

const CustomButton = styled(Button)({
  padding: "15px",
  marginLeft: "0px",
  marginRight: "0px",
  marginTop: "15px",
  marginBottom: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50px",
  boxSizing: "border-box",
  borderRadius: 10,
  background: "#0093f2",
  color: "#ffffff",
  fontWeight: "Condensed Heavy",
  fontSize: "100%",
  transform: "none",
  boxShadow: "none",
  transition: "background 1.0s,border-color .2s,color 0.2s",
  "&:hover": {
    backgroundColor: "green",
  },
});

export default function ArgInputDeploy({ selectedDevices, selectedCommand }) {
  const [args, setArgs] = useState("");

  const deployer = () => {
    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        device_list: selectedDevices.join(","),
        command: selectedCommand,
        arguments: args,
      }),
    };
    fetch("/api/control/command", opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        else if (resp.status === 401) console.error("401 error");
      })
      .then((data) => {
        console.log("Publish Result:", data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const logger = (device) => {
    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: sessionStorage.getItem("email"),
        device: device,
        log_type: "command",
        timestamp: new Date().getTime().toString(),
        log: selectedCommand + " " + args,
      }),
    };
    fetch("/api/add-user-logs", opts) /////////////////////////////////USE ENV VAR HERE!!!!!!!!!!!!!!
      .then((resp) => {
        if (resp.status === 200) return resp.json();
        else if (resp.status === 401) console.error("401 error");
      })
      .then((data) => {
        console.log("Publish Result:", data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleDeployClick = () => {
    //deploy command for device(s)
    deployer();

    selectedDevices.forEach((device) => {
      //log command that was sent for each device
      logger(device);
    });
    setArgs("");
  };

  //styles for selected options
  const styles = {
    width: 100,
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "lightgray",
      };
    },
  };

  return (
    <div
      style={{
        width: "50%",
        marginLeft: "25%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ color: "#C0C0C0", fontWeight: "bold" }}>
        Select and deploy configurations
      </div>
      <br />
      <TextField
        sx={{ backgroundColor: "white", zIndex: 0 }}
        id="args"
        label="Command arguments"
        name="args"
        autoComplete="args"
        autoFocus
        value={args}
        onChange={(e) => setArgs(e.target.value)}
        InputLabelProps={{
          style: { backgroundColor: "#fff", borderRadius: "20px" },
        }}
      />
      <br />
      <br />

      <div style={{ color: "#C0C0C0" }}>
        <div>
          Note: Argument input must follow a standardized format. Please refer
          to device-specific{" "}
          <a
            style={{ color: "#1ED760" }}
            href="https://www.freecodecamp.org/news/how-to-use-html-to-open-link-in-new-tab/"
            target="_blank"
            rel="noopener noreferrer"
          >
            documentation
          </a>{" "}
          before use.
        </div>
      </div>

      <div style={{ flexDirection: "row" }}>
        <CustomButton
          variant="contained"
          component="label"
          onClick={handleDeployClick}
        >
          Deploy Command
        </CustomButton>
      </div>
    </div>
  );
}
