import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/styles';

export default function NotificationButton({handleClick}) {

    const StyledButton = styled(Button)({
        
        marginLeft:"20%",
        marginRight:"0px",
        marginTop:"15px",
        marginBottom:"2px",
        display: "flex",
        width:"40%",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",    
        borderRadius: 10, 
        background: "#0093f2",
        color: "#ffffff",
        fontWeight: "Condensed Heavy",
        fontSize: "100%",
        transform: "none",
        boxShadow: "none",
        transition: "background 1.0s,border-color .2s,color 0.2s",
        "&:hover": {
            backgroundColor:  "green"
        }
    });

  return (
    
      <StyledButton variant="contained" endIcon={<SendIcon/>} onClick={handleClick}>
        Create
      </StyledButton>
    
  );
}