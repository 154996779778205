import React from "react";
import ApexCharts from "react-apexcharts";
import { useState, useEffect } from "react";
import Select from "react-select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Button } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CircularProgress from "@mui/material/CircularProgress";

const datapoints = [615, 1130, 134];

const tableAreaDiv = {
  width: "45%",
  minWidth: "25%",
  background: "#353839",
  border: "6px solid orange",
  borderRadius: "15px",
  marginTop: "5px",
  marginBottom: "10px",
  marginLeft: "2px",
  textAlign: "center",
  color: "black",
  fontSize: "22px",
  padding: "5px",
};

// const rows = [

//     {'reg_addr' : '100', "reg_name" : 'pain level', 'reg_val' : '354'},
//     {'reg_addr' : '101', "reg_name" : 'sad level', 'reg_val' : '984'},
//     {'reg_addr' : '102', "reg_name" : 'tired level', 'reg_val' : '450'},
//     {'reg_addr' : '103', "reg_name" : 'happy level', 'reg_val' : '450'},
//     {'reg_addr' : '104', "reg_name" : 'unhappy level', 'reg_val' : '045'},
//     {'reg_addr' : '105', "reg_name" : 'level level', 'reg_val' : '405'},
//     {'reg_addr' : '106', "reg_name" : 'a level', 'reg_val' : '451'},
//     {'reg_addr' : '107', "reg_name" : 'o level', 'reg_val' : '435'},
//     {'reg_addr' : '108', "reg_name" : 'high level', 'reg_val' : '545'},
//     {'reg_addr' : '109', "reg_name" : 'low level', 'reg_val' : '345'},
// ]

export default function InstantFetchTable({ device, registerAddrList }) {
  console.log(registerAddrList);

  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loadingAddressList, setLoadingAddressList] = useState([]);

  const fetchTableData = () => {
    //newest value of register of device
    fetch(
      "/api/telemetry/instantfetch?device_name=" +
        device +
        "&register_list=" +
        registerAddrList.join(",")
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setRows(data);
        console.log(data.Value);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTableData();
  }, [registerAddrList, device]);

  //Function for the updation of a single register
  const updateRegister = (address_list, index, fullRefresh) => {
    console.log(address_list);
    var temp_rows = rows;
    var currentLoadingList = loadingAddressList;
    setLoadingAddressList(currentLoadingList.concat(address_list));

    fetch(
      "/api/telemetry/instantfetchrequest?device_name=" +
        device +
        "&register_list=" +
        address_list.join(",")
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        //Need to make this compatible with refresh all option
        if (fullRefresh) {
          temp_rows = data;
          setRows(temp_rows);
          console.log("Fetched rows:", rows);
          setRefresh(!refresh);
          setLoadingAddressList([]);
        } else {
          temp_rows[index] = data[0];
          setRows(temp_rows);
          console.log("Fetched rows:", rows);
          setRefresh(!refresh);
          setLoadingAddressList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Value could not be fetched");
        setLoadingAddressList([]);
      });
  };

  return (
    <div style={{ marginTop: "50px", marginLeft: "3px", marginRight: "3px" }}>
      <TableContainer component={Paper} sx={{ overflow: "hidden" }}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="insta fetch table"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "13px" }}
                align="center"
              >
                Address
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "13px" }}
                align="center"
              >
                Name
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "13px" }}
                align="center"
              >
                Sample Timestamp
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "13px" }}
                align="center"
              >
                Value
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "13px" }}
                align="center"
              >
                Unit
              </TableCell>
              <TableCell
                sx={{ fontWeight: "bold", fontSize: "13px" }}
                align="center"
              >
                Refresh
              </TableCell>
              {/* <TableCell
                sx={{ fontWeight: "bold", fontSize: "13px" }}
                align="center"
              >
                <Button
                  sx={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    borderRadius: "100px",
                    backgroundColor: "none",
                    ":hover": { backgroundColor: "#dcf7f6" },
                  }}
                  onClick={() => updateRegister(registerAddrList, null, 1)}
                >
                  Refresh All
                  <AutorenewIcon id={"All"} />
                </Button>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                  align="center"
                >
                  {row.address}
                </TableCell>
                <TableCell sx={{ fontSize: "13px" }} align="center">
                  {row.name}
                </TableCell>
                <TableCell sx={{ fontSize: "13px" }} align="center">
                  {new Date(row.query_timestamp + " GMT").toLocaleString()}
                </TableCell>

                <TableCell sx={{ fontSize: "13px" }} align="center">
                  {row.value}
                </TableCell>
                <TableCell sx={{ fontSize: "13px" }} align="center">
                  {row.unit}
                </TableCell>
                <TableCell sx={{ fontSize: "13px" }} align="center">
                  {loadingAddressList.includes(row.address) ? (
                    <CircularProgress size="1.25rem" />
                  ) : (
                    <Button
                      sx={{
                        borderRadius: "100px",
                        backgroundColor: "none",
                        ":hover": { backgroundColor: "#dcf7f6" },
                      }}
                      onClick={() => updateRegister([row.address], index, 0)}
                    >
                      <AutorenewIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
