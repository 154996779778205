//This component is responsible for fetching and rendering select bars for different device types based on user access.

import { useEffect} from 'react';
import Select from 'react-select'



// CMD_ADD_WP 615,613,617
// CMD_SUB_WP 615,613,617
// CMD_JSON_CONF
// CMD_WRITE 609,6675,610,45676
// CMD_WR_NAME SDCP_DEMO 
// CMD_WIFI_STACONF USERNAME,PASSWORD
// CMD_WIFI_APCONF USERNAME,PASSWORD
// CMD_MQTT_CONF iris.fele.com,1883

var commandOptions = [   {"label":"Add watchpoint (addr1,addr2,addr3,...)","value":"CMD_ADD_WP"},
                        {"label":"Remove watchpoint (addr1,addr2,addr3,...)","value":"CMD_RM_WP"},
                        {"label":"Write to register (addr1,val1,addr2,val2...)","value":"CMD_WRITE"},
                        {"label":"Change device name (new_name)","value":"CMD_WR_NAME"},
                        {"label":"WiFi static configuration (username,password)","value":"CMD_WIFI_STACONF"},
                        {"label":"WiFi AP configuration (username,password)","value":"CMD_WIFI_APCONF"},
                        {"label":"MQTT configuration (hostname, port)","value":"CMD_MQTT_CONF"}
]

var commandInput = []

export default function CommandSelection({setSelectedCommand, disableControl}) {

    const handleChange = (obj) => {
        commandInput = obj
        setSelectedCommand(commandInput.value)
        
    }

    //styles for selected options
    const customStyles = {
        // For the select it self, not the options of the select
        control: (styles, { isDisabled}) => {
          return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            pointerEvents: 'all',
            backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.2)' : 'white',
            color: isDisabled ? 'white' : 'white'
          }
        },
        // For the options
        option: (styles, { isDisabled}) => {
          
          return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            color:'black'
          };
        },
      };

    return(
        <div style={{width:"50%", marginLeft:"25%"}}>
            <div style={{color:"#C0C0C0", fontWeight:"bold"}}>
                Choose command to deploy
            </div>
            <br/>
            <Select
                styles = {customStyles}
                margin = "normal"
                options={commandOptions}  
                placeholder = "Select Command"
                value={commandInput}
                onChange={(e) => handleChange(e)}
                isDisabled = {disableControl}
            />
        </div>
    )
}