import React from "react";
import ApexCharts from "react-apexcharts";
import { useState, useEffect } from "react";
import Select from "react-select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import LinearProgress from "@mui/material/LinearProgress";
import Writer from "./ioTableWriter";
import CircularProgress from "@mui/material/CircularProgress";

export default function IOTable({
  device,
  registerAddrList,
  sectionFetchComplete,
}) {
  const [tableFetchComplete, setTableFetcComplete] = useState(false);

  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loadingAddressList, setLoadingAddressList] = useState([]);

  const fetchTableData = () => {
    //newest value of register of device
    fetch(
      "/api/telemetry/instantfetch?device_name=" +
        device +
        "&register_list=" +
        registerAddrList.join(",")
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        setRows(data);
        setTableFetcComplete(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchTableData();
  }, [registerAddrList]);

  //Function for the updation of a single register
  const updateRegister = (address_list, index, fullRefresh) => {
    var temp_rows = rows;
    var currentLoadingList = loadingAddressList;
    setLoadingAddressList(currentLoadingList.concat(address_list));
    fetch(
      "/api/telemetry/instantfetchrequest?device_name=" +
        device +
        "&register_list=" +
        address_list.join(",")
    ) /// ENV
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        //Need to make this compatible with refresh all option
        if (fullRefresh) {
          temp_rows = data;
          setRows(temp_rows);
          console.log("Fetched rows:", rows);
          setRefresh(!refresh);
          setLoadingAddressList([]);
        } else {
          temp_rows[index] = data[0]; //only one row is returned
          setRows(temp_rows);
          setRefresh(!refresh);
          setLoadingAddressList([]);
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Value could not be fetched");
        setLoadingAddressList([]);
      });
  };

  return (
    <div>
      {tableFetchComplete && sectionFetchComplete ? (
        <TableContainer
          component={Paper}
          sx={{ minHeight: "305px", overflow: "auto" }}
        >
          <Table
            sx={{ minWidth: 650 }}
            aria-label="insta fetch table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "13px" }}
                  align="center"
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "13px" }}
                  align="center"
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "13px" }}
                  align="center"
                >
                  Sample Timestamp
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "13px" }}
                  align="center"
                >
                  Value
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "13px" }}
                  align="center"
                >
                  Unit
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "13px" }}
                  align="center"
                >
                  <Button
                    sx={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      borderRadius: "100px",
                      backgroundColor: "none",
                      ":hover": { backgroundColor: "#dcf7f6" },
                    }}
                    onClick={() => updateRegister(registerAddrList, null, 1)}
                  >
                    Refresh All
                    <AutorenewIcon id={"All"} />
                  </Button>
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontSize: "13px" }}
                  align="center"
                >
                  Write
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row, index) => (
                  <TableRow
                    key={row.address}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      sx={{ fontSize: "13px" }}
                      component="th"
                      scope="row"
                      align="center"
                    >
                      {row.address}
                    </TableCell>
                    <TableCell sx={{ fontSize: "13px" }} align="center">
                      {row.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: "13px" }} align="center">
                      {new Date(row.query_timestamp + " GMT").toLocaleString()}
                    </TableCell>
                    <TableCell sx={{ fontSize: "13px" }} align="center">
                      {row.value}
                    </TableCell>
                    <TableCell sx={{ fontSize: "13px" }} align="center">
                      {row.unit}
                    </TableCell>
                    <TableCell sx={{ fontSize: "13px" }} align="center">
                      {loadingAddressList.includes(row.address) ? (
                        <CircularProgress size="1.25rem" />
                      ) : (
                        <Button
                          sx={{
                            borderRadius: "100px",
                            backgroundColor: "none",
                            ":hover": { backgroundColor: "#dcf7f6" },
                          }}
                          onClick={() =>
                            updateRegister([row.address], index, 0)
                          }
                        >
                          <AutorenewIcon />
                        </Button>
                      )}
                    </TableCell>
                    {row.writable ? (
                      <TableCell
                        sx={{
                          fontSize: "11px",
                          width: "17%",
                          minWidth: "25vh",
                        }}
                        align="center"
                      >
                        <Writer deviceName={device} regAddress={row.address} />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ fontSize: "13px", color: "#C0C0C0" }}
                        align="center"
                      >
                        No write access
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ marginTop: "10px", color: "#1ED760" }}>
          <LinearProgress color="inherit" />
        </div>
      )}
    </div>
  );
}
