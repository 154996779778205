import {useEffect} from 'react'

export default function Logout() {
  
    {
      useEffect(() =>{
        sessionStorage.clear();
      },);
    }
  
    return (
      
      <div >
                {sessionStorage.getItem('access_token') ? (window.location.href = '/logout')         
                :
                (<div>
                    <h2>Successfully logged out</h2>   
                </div>)
                }
      </div>
    );
  }