import React from 'react'
import ApexCharts from 'react-apexcharts'
import {useState, useEffect} from 'react'
import { Grid, TextField, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import { styled } from '@mui/styles';


var start = 0



export default function DashboardChart({deviceName, startTime, stopTime, registerAddrList, chartOptions, refreshRate}) {
 
  //const liveRefreshRate = 1000
  
  const [series, setSeries] = useState([])

  const getChartData = () => {

    //console.log("start", startTime, "stop", stopTime, "regList", registerAddrList)
    
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    var device = params.get('device_name')

    //newest value of register of device
    fetch("/api/telemetry/chartdata?device_name=" + deviceName + "&register_list=" + registerAddrList.join(',') + "&start_time=" + startTime + "&stop_time=" + stopTime) /// ENV
        .then(resp =>{
          if(resp.status === 200) return resp.json(); 
        })
        .then(data => {
          setSeries(data)
          //console.log(series)
        })
        .catch((err) => {
          console.log(err)
        });
  }

  useEffect(() => {

    const getAPIData = async () => {
      try{
        if(registerAddrList.length){
          await getChartData();
        }
         
      }
      catch(err){
        console.log(err)
      }
    }
    
    getAPIData();

    const interval = setInterval(() => {
      getAPIData()
    },refreshRate);
    return () => clearInterval(interval);
    
  },[, deviceName, startTime, stopTime, registerAddrList]);

  
    return(  
  
          <div style={{marginTop:"10px", marginLeft:"3px", marginRight:"3px"}}>
          
            <ApexCharts options={chartOptions} series={series} type="line" height={305} width={"99%"}/>
          </div>
  
    ) 
}