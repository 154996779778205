import { useSearchParams, useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import WidgetPage from '../components/widgetPage';
import { CircularProgress } from '@material-ui/core';
import Unauthorized from '../components/unauthorized';


export default function DeviceDashboard() {
   
    const [renderDefaults, setRenderDefaults] = useState(null);
    const [authorized, setAuthorized] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    
    //const [device, setDevice] = useState(searchParams.get("device_name"))
    var device = searchParams.get("device_name")

    const location = useLocation();

    
    //renderDefaults = null
    //const renderDefaults = useRef(null)

    const fetchDefaults = () =>  {
        
        fetch("/api/inventory/render-defaults?device_name="+device) /// ENV
        .then(resp =>{
        if(resp.status === 200) return resp.json(); 
        })
        .then(data => {
            //renderDefaults.current=data 
            //console.log(renderDefaults.current)
            setRenderDefaults(data) 
        })
        .catch((err) => {
            console.log(err)
            });
    }


    useEffect(() => {
        setAuthorized(false)
        setRenderDefaults(null)
        device = searchParams.get("device_name")
        fetchDefaults()

        fetch("/api/inventory/authorized-devices?email="+sessionStorage.getItem("email")) /// ENV
        .then(resp =>{
          if(resp.status === 200) return resp.json();
        })
        .then(data => {
            
            //Procesing fetched data to populate deviceList
            for (const [category, devices] of Object.entries(data)) {

                if(devices.includes(device)) {
                    setAuthorized(true)
                    break;
                }       
            }
        })
        .catch((err) => {
          console.log(err)
        });
        
      
    }, [location])

    if(renderDefaults != null && device == renderDefaults.name && authorized){
        return(
            <div style ={{marginLeft:"30px", marginRight:"30px", marginBottom:"30px"}}>
                {console.log(device,renderDefaults)}
                <WidgetPage deviceName={device} renderDefaults={renderDefaults}/>
            </div>   
        )
    }

    else if(renderDefaults != null && device == renderDefaults.name && !authorized){
        return(
            <>
                <Unauthorized/>
            </>   
        )
    }
    else{
        return(
            <div style ={{justifyContent:"center", alignItems:"center", display:"flex", minHeight:"100vh"}}>
                <CircularProgress/>
            </div> 
        )
    }

    
}