import { useState, useEffect, Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./views/landing";
import SignInSide from "./views/login";
import UserCreate from "./views/admin/createUser";
import AddDevice from "./views/admin/addDevice";
import Logout from "./views/logout";
import Navbar from "./components/navbar/navbar";
import RemoveDevice from "./views/admin/removeDevice";
import DeviceDashboard from "./views/deviceDashboard";
import MapView from "./views/map";
import Home from "./views/home";
import HomeTiles from "./components/homeTiles";
import RemoteControlPanel from "./views/remoteControlPanel";
import IRISManagement from "./views/irisManagement";
import Notifications from "./views/notifications";
import FEConnectCrashLogs from "./views/admin/feConnectCrash";
import Unauthorized from "./components/unauthorized";
import React from "react";
import {
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";

const generateClassName = createGenerateClassName({
  productionPrefix: "prod",
});

function App() {
  const [auth, setAuth] = useState(false);

  useEffect(() => {}, [auth]);

  useEffect(() => {
    // send jwt to API to see if it's valid
    let token = sessionStorage.getItem("access_token");
    const opts = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    if (token) {
      fetch("/api/auth/verify", opts)
        .then((resp) => {
          if (resp.status === 200) return resp.json();
        })
        .then((data) => {
          if (data.status === "ok") setAuth(true);
        })
        .catch((err) => {
          setAuth(false);
          sessionStorage.removeItem("token");
        });
    } else console.log("Invalid token");
  }, []);

  return (
    <>
      <StylesProvider generateClassName={generateClassName}>
        {auth ? (
          <>
            <Navbar />
            <div style={{ backgroundColor: "#353935" }}>
              <Routes>
                {/* <Route path = "/" exact element={<MapView/>}/>
              <Route path = "/landing" exact element={<MapView/>}/> */}
                <Route path="/" exact element={<HomeTiles />} />
                <Route path="/landing" exact element={<HomeTiles />} />

                <Route path="/logout" exact element={<Logout />} />

                <Route path="/device-dash" element={<DeviceDashboard />} />
                <Route path="/map" exact element={<MapView />} />
                <Route
                  path="/notifications"
                  exact
                  element={<Notifications />}
                />

                {/*admin routes need protection */}
                {sessionStorage.getItem("admin_access") == "true" ? (
                  <>
                    <Route path="/createuser" exact element={<UserCreate />} />
                    <Route path="/adddevice" exact element={<AddDevice />} />
                    <Route
                      path="/feconnect-crash-logs"
                      exact
                      element={<FEConnectCrashLogs />}
                    />
                    <Route
                      path="/removedevice"
                      exact
                      element={<RemoveDevice />}
                    />
                  </>
                ) : (
                  <>
                    <Route
                      path="/createuser"
                      exact
                      element={<Unauthorized />}
                    />
                    <Route path="/adddevice" exact element={<Unauthorized />} />
                    <Route
                      path="/feconnect-crash-logs"
                      exact
                      element={<Unauthorized />}
                    />
                    <Route
                      path="/removedevice"
                      exact
                      element={<Unauthorized />}
                    />
                  </>
                )}

                {sessionStorage.getItem("rc_access") == "true" ? (
                  <>
                    <Route
                      path="/remote-control"
                      exact
                      element={<RemoteControlPanel />}
                    />
                    <Route
                      path="/iris-management"
                      exact
                      element={<IRISManagement />}
                    />
                  </>
                ) : (
                  <>
                    <Route
                      path="/remote-control"
                      exact
                      element={<Unauthorized />}
                    />
                    <Route
                      path="/iris-management"
                      exact
                      element={<Unauthorized />}
                    />
                  </>
                )}
              </Routes>
            </div>
          </>
        ) : (
          <SignInSide />
        )}
      </StylesProvider>
    </>
  );
}

export default App;
