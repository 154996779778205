/////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
// This is a React component that renders a pump curve chart using D3.js library //
/////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import "../../css/chart.css";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import PumpInfoWindow from "./pumpInfoWindow";
import { set } from "date-fns";

const severityIcons = [
  <ErrorIcon
    sx={{
      fontSize: "25px",
      color: "#ff0000", // Red: rgba(255, 0, 0) -> #ff0000
    }}
  />,
  <WarningIcon
    sx={{
      fontSize: "25px",
      color: "#ffd343", // Yellow: rgba(255, 211, 67) -> #ffd343
    }}
  />,
  <CheckCircleIcon
    sx={{
      fontSize: "25px",
      color: "#1ed760", // Green: rgba(30, 215, 96) -> #1ed760
    }}
  />,
];

// Pump curve component
const PumpCurveSvg = ({
  device,
  flowMode,
  totalDynamicHead,
  capacity,
  motorSpeed,
  tableRegisters,
  //geometry
  rpmCurves,
  efficiencyCurves,
  efficiencyRegions,
  nonRegionCardText,
  currentRPMCurve,
  flowFromRPM,
  bestEfficiencyPoint,
  window,
}) => {
  //Mutable Refs
  const svgRef = useRef();
  const gRef = useRef();
  const tooltipRef = useRef();
  const cardRef = useRef();
  const regionDataRef = useRef([]); //stores a working copy of each region's (polygon's) data

  //States
  const [cardData, setCardData] = useState({ title: "", body: "", icon: 0 });

  const [capacityStateVar, setCapacityStateVar] = useState(capacity);

  const margin = { top: 20, right: 30, bottom: 60, left: 60 };
  const width = 960 - margin.left - margin.right;
  const height = 600 - margin.top - margin.bottom;

  // Update chart. Dependencies: totalDynamicHead, capacity
  useEffect(() => {
    //when not in flow mode capacity must be inferred from total dynamic head and motor speed
    if (!flowMode) {
      capacity = flowFromRPM(motorSpeed, totalDynamicHead);
      setCapacityStateVar(capacity);
    }

    //force state change in child component(s) when flowMode is toggled by user
    if (capacity != capacityStateVar) {
      setCapacityStateVar(capacity);
    }

    console.log("Total Dynamic Head: ", totalDynamicHead);
    console.log("Capacity: ", capacity);
    console.log("Motor speed: ", motorSpeed);

    const margin = { top: 20, right: 60, bottom: 60, left: 60 };
    const width = 960 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;

    // Initialize SVG only once
    if (!gRef.current) {
      const svg = d3
        .select(svgRef.current)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      gRef.current = svg;

      // Define scales
      const xScale = d3.scaleLinear().domain([0, window.x]).range([0, width]);
      const yScale = d3.scaleLinear().domain([0, window.y]).range([height, 0]);
      // const yRightScale = d3.scaleLinear().domain([0, 100]).range([height, 0]);

      // Define axes
      const xAxis = d3.axisBottom(xScale);
      const yAxis = d3.axisLeft(yScale);
      // const yRightAxis = d3.axisRight(yRightScale);

      // Add X axis
      svg.append("g").attr("transform", `translate(0,${height})`).call(xAxis);

      // Add Y axis
      svg.append("g").call(yAxis);

      // Add Y right axis
      // svg
      //   .append("g")
      //   .attr("transform", `translate(${width},0)`)
      //   .call(yRightAxis);

      // Add X gridlines
      svg
        .append("g")
        .attr("class", "grid")
        .attr("transform", `translate(0,${height})`)
        .style("opacity", 0.1)
        .call(d3.axisBottom(xScale).tickSize(-height).tickFormat(""));

      // Add Y gridlines
      svg
        .append("g")
        .attr("class", "grid")
        .style("opacity", 0.1)
        .call(d3.axisLeft(yScale).tickSize(-width).tickFormat(""));

      // Add labels

      //Capacity
      svg
        .append("text")
        .attr(
          "transform",
          `translate(${width / 2},${height + margin.top + 20})`
        )
        .style("text-anchor", "middle")
        .style("fill", "white")
        .style("font-size", "16px")
        .text("Capacity (gpm)");

      //Total Dynamic Head
      svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 0 - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("fill", "white")
        .style("font-size", "16px")
        .text("Total Dynamic Head (feet)");

      //Efficiency
      // svg
      //   .append("text")
      //   .attr("transform", "rotate(90)")
      //   .attr("y", 0 - width - margin.right)
      //   .attr("x", height / 2)
      //   .attr("dy", "1em")
      //   .style("text-anchor", "middle")
      //   .style("fill", "white")
      //   .style("font-size", "16px")
      //   .text("Efficiency %");

      // Add clip path
      svg
        .append("defs")
        .append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

      const curveGroup = svg.append("g").attr("clip-path", "url(#clip)");

      // Add efficiency regions

      efficiencyRegions.forEach(
        ({ regionName, color, mouseoverColor, boundaries, cardText }) => {
          var regionBoundary = [];
          var lastPenUpX = 0;
          boundaries.forEach(
            ({ equationY, equationX, lineData, lower, upper }) => {
              var data;
              //if equations are not provided, but line data is, use line data to get boundary data
              if (equationX === undefined || equationY === undefined) {
                data = lineData;
              }
              //boundary data if equations are provided
              else {
                data = d3
                  .range(lower, upper, 1)
                  .map((d) => [equationX(d), equationY(d)]);
              }

              console.log("Efficiency region D3 Data:", data);

              //Ensure coordinates are ordered such that pen never has to be lifetd when creating path.
              //This is done by reversing the data array if the last pen up x is greater than the current pen down x.
              //Failing to do this will result in extra lines being drawn where the pen is lifted and placed back down.
              var currentPenDownX = data[0][0];
              if (lastPenUpX > currentPenDownX) {
                data.reverse();
              }
              regionBoundary.push(...data);
              lastPenUpX = data[data.length - 1][0];
            }
          );

          regionDataRef.current.push({
            name: regionName,
            polygon: regionBoundary,
            cardText: cardText,
          });

          var lineGenerator = d3
            .line()
            .x((d) => xScale(d[0]))
            .y((d) => yScale(d[1]));

          var pathString = lineGenerator(regionBoundary);

          svg
            .append("path")
            .attr("d", pathString)
            .attr("class", regionName)
            .attr("stroke", "none")
            .attr("fill", color);
          // .on("mouseover", function () {
          //   d3.select(this).transition().duration(200).style("opacity", 0.9);
          // })
          // .on("mouseout", function () {
          //   d3.select(this).transition().duration(200).style("fill", color);
          // });
        }
      );

      //console.log("Region Data:", regionDataRef.current);

      // Add pump curves
      rpmCurves.forEach(({ label, equation, terminate, labelShift }) => {
        const data = d3
          .range(0, window.x, 1)
          .map((d) => ({
            capacity: d,
            head: equation(d),
          }))
          .filter((d) => d.capacity >= 0 && d.capacity <= terminate);

        const line = d3
          .line()
          .x((d) => xScale(d.capacity))
          .y((d) => yScale(d.head));

        svg
          .append("path")
          .datum(data)
          .attr("class", `pump-curve-line rpm-${label}`)
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", "3,3")
          .attr("d", line);

        const firstPoint = data[0];

        // Add RPM label
        curveGroup
          .append("text")
          .attr("x", xScale(firstPoint.capacity))
          .attr("y", yScale(firstPoint.head))
          .attr("dy", `${labelShift}`)
          .attr("dx", "0.5em")
          .attr("fill", "white")
          .text(`${label} RPM`)
          .style("font-size", "11px");
      });

      //Add efficiency curves
      efficiencyCurves.forEach(({ label, labelShift, lineData }) => {
        const line = d3
          .line()
          .x((d) => xScale(d.capacity))
          .y((d) => yScale(d.head))
          .curve(d3.curveBasis);

        svg
          .append("path")
          .datum(lineData)
          .attr("class", `efficiency-curve-line ${label}`)
          .attr("fill", "none")
          .attr("stroke", "white")
          .attr("stroke-width", 1)
          .attr("stroke-dasharray", "2,3")
          .attr("d", line);

        const lastPoint = lineData[lineData.length - 1];

        // Add Efficiency Line label
        curveGroup
          .append("text")
          .attr("x", xScale(lastPoint.capacity))
          .attr("y", yScale(lastPoint.head))
          .attr("dy", `${labelShift}`)
          .attr("dx", "0.5em")
          .attr("fill", "white")
          .text(`${label} (%)`)
          .style("font-size", "11px");
      });

      //Initial Current RPM Curve
      const currentRPMData = currentRPMCurve(totalDynamicHead, capacity);

      const currentRPMLine = d3
        .line()
        .x((d) => xScale(d.capacity))
        .y((d) => yScale(d.head));

      console.log("Current RPM Data:", currentRPMData);

      svg
        .append("path")
        .datum(currentRPMData.data)
        .attr("class", `current-rpm-curve`)
        .attr("fill", "none")
        .attr("stroke", "white")
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", "3,3")
        .attr("d", currentRPMLine);

      const firstPoint = currentRPMData.data[0];

      // Add RPM label
      curveGroup
        .append("text")
        .attr("class", `current-rpm-label`)
        .attr("x", xScale(firstPoint.capacity))
        .attr("y", yScale(firstPoint.head))
        .raise()
        .attr("dy", `-0.5em`)
        .attr("dx", "0.5em")
        .attr("fill", "white")
        .text(`${currentRPMData.rpmCalculated.toFixed(0)} RPM`)
        .style("font-size", "11px");

      // Add X axis
      svg.append("g").attr("transform", `translate(0,${height})`).call(xAxis);

      // Add Y axis
      svg.append("g").call(yAxis);

      // Initial dynamic point
      svg
        .append("circle")
        .attr("class", "dot")
        .attr("r", 5)
        .attr("fill", "red");

      // Initial horizontal connector
      svg
        .append("line")
        .attr("class", "horizontal-line")
        .attr("stroke", "white")
        .attr("stroke-width", 0.75);
      // .attr("stroke-dasharray", "1,3");

      // Initial vertical connector
      svg
        .append("line")
        .attr("class", "vertical-line")
        .attr("stroke", "white")
        .attr("stroke-width", 0.75);
      // .attr("stroke-dasharray", "1,3");

      // Best Efficiency Point (static, no updates)
      var sym = d3.symbol().type(d3.symbolStar).size(175);
      svg
        .append("path")
        .attr("d", sym)
        .attr("class", "bep")
        .attr(
          "transform",
          `translate(${xScale(bestEfficiencyPoint.capacity)}, ${yScale(
            bestEfficiencyPoint.head
          )})`
        )
        .attr("fill", "gold")
        .attr("stroke", "orange");

      // Define the arrowhead marker variables
      const markerBoxWidth = 10;
      const markerBoxHeight = 10;
      const refX = markerBoxWidth / 2;
      const refY = markerBoxHeight / 2;
      const markerWidth = markerBoxWidth / 2;
      const markerHeight = markerBoxHeight / 2;
      const arrowPoints = [
        [0, 0],
        [5, 5],
        [0, 10],
        [10, 5],
      ];
      // Add the arrowhead marker definition to the svg element
      svg
        .append("defs")
        .append("marker")
        .attr("id", "arrow")
        .attr("viewBox", [0, 0, markerBoxWidth, markerBoxHeight])
        .attr("refX", refX)
        .attr("refY", refY)
        .attr("markerWidth", markerBoxWidth)
        .attr("markerHeight", markerBoxHeight)
        .attr("orient", "auto-start-reverse")
        .append("path")
        .attr("d", d3.line()(arrowPoints))
        .attr("stroke", "white")
        .attr("fill", "white");

      const xScaleFactor = window.x / 1250; // Scale based on the original x = 1250
      const yScaleFactor = window.y / 500; // Scale based on the original y = 500

      // Arrow to best efficiency point
      svg
        .append("path")
        .attr(
          "d",
          d3.line()([
            [
              xScale(bestEfficiencyPoint.capacity + 30 * xScaleFactor),
              yScale(bestEfficiencyPoint.head),
            ],
            [
              xScale(bestEfficiencyPoint.capacity + 87.5 * xScaleFactor),
              yScale(bestEfficiencyPoint.head),
            ],
            [
              xScale(bestEfficiencyPoint.capacity + 145 * xScaleFactor),
              yScale(bestEfficiencyPoint.head + 20 * yScaleFactor),
            ],
          ])
        )
        .attr("stroke", "white")
        // .attr("markerWidth", 30)
        // .attr("markerHeight", 30)
        // .attr("orient", "auto-start-reverse")
        .attr("marker-start", "url(#arrow)")
        .attr("fill", "none");

      //Add "Best Efficiency Point" label
      svg
        .append("text")
        .attr("x", xScale(bestEfficiencyPoint.capacity + 85 * xScaleFactor))
        .attr("y", yScale(bestEfficiencyPoint.head + 30 * yScaleFactor))
        .attr("dy", "0.5em")
        .attr("dx", "0em")
        .attr("fill", "white")
        .text("Best Efficiency Point")
        .style("font-size", "11px");
    }

    const svg = gRef.current;

    // Define scales
    const x = d3
      .scaleLinear()
      .domain([0, window.x])
      .range([0, 960 - margin.left - margin.right]);
    const y = d3
      .scaleLinear()
      .domain([0, window.y])
      .range([600 - margin.top - margin.bottom, 0]);

    // Update dynamic point with animation
    svg
      .selectAll(".dot")
      .data([{ capacity, head: totalDynamicHead }])
      .transition()
      .duration(500)
      .attr("cx", x(capacity))
      .attr("cy", y(totalDynamicHead));

    // Update horizontal connector with animation
    svg
      .selectAll(".horizontal-line")
      .transition()
      .duration(500)
      .attr("x1", 0)
      .attr("y1", y(totalDynamicHead))
      .attr("x2", x(capacity))
      .attr("y2", y(totalDynamicHead));

    // Update vertical connector with animation
    svg
      .selectAll(".vertical-line")
      .transition()
      .duration(500)
      .attr("x1", x(capacity))
      .attr("y1", 600 - margin.top - margin.bottom)
      .attr("x2", x(capacity))
      .attr("y2", y(totalDynamicHead));

    // Highlight operating region
    resetOriginalColors(); // return region colors to original state (also resets animations)

    //Update current rpm curve
    const currentRPMData = currentRPMCurve(totalDynamicHead, capacity);
    const currentRPMLine = d3
      .line()
      .x((d) => x(d.capacity))
      .y((d) => y(d.head));

    svg
      .selectAll(".current-rpm-curve")
      .datum(currentRPMData.data)
      .transition()
      .duration(500)
      .attr("d", currentRPMLine);

    const firstPoint = currentRPMData.data[0];

    // Update RPM label
    svg
      .selectAll(".current-rpm-label")
      .data([firstPoint])
      .join("text")
      .attr("class", "current-rpm-label")
      .raise()
      .transition()
      .duration(500)
      .attr("x", x(firstPoint.capacity))
      .attr("y", y(firstPoint.head))
      .attr("dy", "-0.5em")
      .attr("dx", "0.5em")
      .attr("fill", "white")
      .style("background-color", "rgba(1, 1, 1, 1)") // Add background
      .text(`${currentRPMData.rpmCalculated.toFixed(0)} RPM`)
      .style("font-size", "11px");

    // Add tooltip
    const tooltip = d3.select(tooltipRef.current);

    svg
      .selectAll(".dot")
      .on("mouseover", (event, d) => {
        const [x, y] = d3.pointer(event); // Get coordinates relative to the SVG
        tooltip
          .style("left", `${x + 70}px`)
          .style("top", `${y - 55}px`) // Adjust the offset as needed
          .style("display", "inline-block")
          .html(
            `<b>Capacity:</b> ${d.capacity} gpm<br><br><b>Head:</b> ${d.head} ft`
          );
      })
      .on("mouseout", () => {
        tooltip.style("display", "none");
      });

    // Check if the dot lies within a polygon in regionData
    function getOperationgRegionIndex(capacity, totalDynamicHead) {
      //   console.log("Checking Operating Region...");
      //   console.log("regionDataRef.current.length", regionDataRef.current.length);
      for (var i = 0; i < regionDataRef.current.length; i++) {
        if (
          d3.polygonContains(regionDataRef.current[i]["polygon"], [
            capacity,
            totalDynamicHead,
          ])
        ) {
          return i;
        }
      }
      return null;
    }

    // Update card position and content
    const regionIndex = getOperationgRegionIndex(capacity, totalDynamicHead);
    //console.log("Region Index:", regionIndex);

    if (regionIndex === null) {
      setCardData(nonRegionCardText);
    } else if (regionIndex !== undefined) {
      //console.log("Updating Card Data...");
      setCardData(
        regionDataRef.current[
          getOperationgRegionIndex(capacity, totalDynamicHead)
        ]["cardText"]
      );

      //console.log("Card Data:", cardData);
    }

    // Define pulse animation
    function pulseAnimationDot() {
      svg
        .selectAll(".dot")
        .transition()
        .duration(500)
        .attr("cx", x(capacity))
        .attr("cy", y(totalDynamicHead))
        .attr("stroke-width", 12)
        .style("opacity", 0.5)
        .transition()
        .duration(500)
        .attr("cx", x(capacity))
        .attr("cy", y(totalDynamicHead))
        .attr("stroke", "white")
        .attr("stroke-width", 0.5)
        .style("opacity", 1)
        .on("end", pulseAnimationDot); // Loop the animation
    }

    function pulseAnimationRegion() {
      try {
        svg
          .selectAll(
            ".".concat(
              regionDataRef.current[
                getOperationgRegionIndex(capacity, totalDynamicHead)
              ]["name"]
            )
          )
          .transition()
          .duration(500)
          .style("opacity", "1")
          .transition()
          .duration(500)
          .style("opacity", "0.85")
          .on("end", pulseAnimationRegion); // Loop the animation
      } catch (e) {
        console.log("Error in pulseAnimationRegion:", e);
      }
    }

    // Reset original colors when dot moves
    function resetOriginalColors() {
      efficiencyRegions.forEach(({ regionName, color }) => {
        svg
          .selectAll(".".concat(regionName))
          .transition()
          .duration(1000)
          .style("fill", color);
      });
    }

    //start pulse animation
    pulseAnimationDot();
    pulseAnimationRegion();
  }, [totalDynamicHead, capacity, motorSpeed, flowMode]);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ position: "relative" }}>
        <svg ref={svgRef}></svg>
        <div
          ref={tooltipRef}
          className="tooltip"
          style={{
            position: "absolute",
            display: "none",
            backgroundColor: "white",
            opacity: "0.8",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid black",
            pointerEvents: "none",
            fontSize: "14px",
            color: "black",
          }}
        ></div>

        <div
          ref={cardRef}
          className="card"
          style={{
            position: "absolute",
            maxWidth: "25%",
            bottom: `${margin.bottom + 20}px`,
            right: `${margin.right + 30}px`,
            marginRight: "10px",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid black",
            pointerEvents: "none",
            fontSize: "14px",
            color: "black",
            display: cardData.title ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <b style={{ marginRight: "10px" }}>{cardData.title}</b>
            {severityIcons[cardData.icon]}
          </div>
          <br />
          <div>{cardData.body}</div>
        </div>
      </div>
      <div>
        <PumpInfoWindow
          device={device}
          flowMode={flowMode}
          totalDynamicHead={totalDynamicHead}
          capacity={capacityStateVar}
          motorSpeed={motorSpeed}
          tableRegisterList={tableRegisters}
        />
      </div>
    </div>
  );
};

export default PumpCurveSvg;
