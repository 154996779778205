import IRISList from "../components/irisList";
import IRISFirmware from "../components/irisFW";
import { useState } from "react";
import { styled } from "@mui/styles";
import Button from "@mui/material/Button";

const CustomButton = styled(Button)({
  width: "81.10%",
  padding: "15px",
  marginLeft: "10%",
  marginRight: "0px",
  marginTop: "15px",
  marginBottom: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50px",
  boxSizing: "border-box",
  borderRadius: 10,
  background: "#0093f2",
  color: "#ffffff",
  fontWeight: "Condensed Heavy",
  fontSize: "100%",
  transform: "none",
  boxShadow: "none",
  transition: "background 1.0s,border-color .2s,color 0.2s",
  "&:hover": {
    backgroundColor: "green",
  },
});

export default function IRISManagement() {
  const [irisSelection, setIrisSelection] = useState([]);
  const [fwSelection, setFWSelection] = useState();

  const handleDeployClick = () => {
    var body = fwSelection;
    body.device_list = irisSelection;

    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    fetch("/api/control/deploy-ota", opts)
      .then((resp) => {
        if (resp.status === 200) return resp.json();
      })
      .then((data) => {
        console.log("Publish Result:", data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  // Inline-Styles for divs
  // Banner styles
  const mainBannerDiv = {
    width: "99%",
    minWidth: "99%",
    marginLeft: "10px",
    marginRight: "10px",
    height: "40px",
    background: "#224663",
    border: "0.1px solid #1ED760",
    borderRadius: "15px",
    marginTop: "3px",
    textAlign: "center",
    lineHeight: "2.5",
    color: "white",
    fontSize: "16px",
  };

  const subBanner = {
    border: "0.3px solid white",
    borderRadius: "15px",
    color: "white",
    fontSize: "16px",
    margin: "3px",
  };

  var dialContainerDiv = {
    height: "auto",
    width: "40%",
    minWidth: "210px",
    background: "linear-gradient(to top,#224663, #353839)",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    marginTop: "5px",
    marginBottom: "10px",
    marginLeft: "1%",
    marginRight: "0.10%",
    textAlign: "center",
    color: "black",
    fontSize: "16px",
    padding: "5px",
    overflow: "auto",
  };

  return (
    <div
      style={{
        marginLeft: "30px",
        marginRight: "30px",
        marginBottom: "30px",
      }}
    >
      <div style={mainBannerDiv}>IRIS Management</div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {
          // Panel for IRIS devices
        }
        <div style={dialContainerDiv}>
          <div style={subBanner}>Your IRIS devices</div>
          <IRISList setIrisSelection={setIrisSelection} />
        </div>

        {
          // Panel for FW versions
        }
        <div style={dialContainerDiv}>
          <div style={subBanner}>Available Firmware Updates</div>
          <IRISFirmware setFWSelection={setFWSelection} />
        </div>
      </div>
      {console.log("fw:", fwSelection)}
      {console.log("iris:", irisSelection)}
      <CustomButton
        className="uniqueclass"
        onClick={handleDeployClick}
        component="label"
        variant="outlined"
      >
        Deploy Firmware{" "}
        {fwSelection !== undefined ? "Version " + fwSelection.version : ""} on{" "}
        {irisSelection.length} Device(s)
      </CustomButton>
    </div>
  );
}
