import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import '../../css/addDevice.css'


export default function RemoveDevice() {

  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState("");
  var allDevices = []
  
  useEffect(() => {
    allDevices = []
    
    fetch("/api/inventory/authorized-devices?email="+sessionStorage.getItem("email")) /// ENV
    .then(resp =>{
      if(resp.status === 200) return resp.json(); 
    })
    .then(data => {
        //console.log("Data:", data)
        allDevices = Object.keys(data).map(function(key){
          return data[key];
      });
      setDeviceList(allDevices.flat())
      console.log(allDevices)
        
    })
    .catch((err) => {
      console.log(err)
    });

          
  }, [])

  const deleteButtonClick = () => {
    
    
    fetch("/api/admin/deletedevice?device_list=" + selectedDevices) /// ENV
    .then(resp =>{
    if(resp.status === 200) return resp.json(); 
    })
    .then(data => {
      window.location.reload()
    })
    .catch((err) => {
    console.log(err)
    });

  }

  return (
    <div style={{margin:"2%", color:"white", background:"linear-gradient(to left, #005DAA, #353839)"}}>
      <form className="form" autoComplete="off">
        <div className="form-field">
          <label><h1 style={{color:"white"}}>Delete your devices here:</h1></label>
          <br/>
          <p>
            <b>WARNING!</b><br/> This is an irreversible action. All telemetry data, status info and notifications created for selected device(s) will be erased.
          </p>
          <br/>
          <h3>Device List:</h3>
          
          {deviceList.map((device, index)=>(
            <div>{device}</div>
          ))}
          <br/>
          <h3 style={{color:"white"}}>Comma-separated list of device(s) to deleted:</h3>                
          
          <TextField
            margin="none"
            required
            style = {{width: "30%"}}
            id="devlist"
            label="Device(s) to be deleted"
            name="devlist"
            autoComplete="devlist"
            autoFocus
            value = {selectedDevices}
            onChange = {(e) => setSelectedDevices(e.target.value)}
            InputProps={{
                style: {
                    background: "white"
                }
            }}
            InputLabelProps={{style: { backgroundColor: '#fff', borderRadius:"20px"}}}
          />
          <br/><br/>
          <div className="second-division">
                          
            <button
              type="button"
              onClick={deleteButtonClick}
              className="remove-btn"
            >
            <span>Confirm and Delete</span>
            </button>
            
          </div>
          <br/><br/>

         
        </div>
      </form>
    </div>
    
  );
}
