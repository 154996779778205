//This component is responsible for fetching and rendering select bars for different device types based on user access.

import { useEffect, useState} from 'react';

import Select from 'react-select'


var deviceInput = []
var categoryOptions = []
var apiObject = []
var disableDeviceField = true

export default function ControlDeviceSelection({setSelectedDevices, setDisableControl}) {

    const [categoryInput, setCategoryInput] = useState()
    const [deviceOptions, setDeviceOptions] = useState([])

    useEffect(() => {
        
        categoryOptions = []    //refresh options to avoid duplicate options
        disableDeviceField = true
        
        fetch("/api/inventory/authorized-devices?email="+sessionStorage.getItem("email")) /// ENV
        .then(resp =>{
          if(resp.status === 200) return resp.json(); 
        })
        .then(data => {
            //console.log("Data:", data)
            apiObject = data
            Object.keys(apiObject).forEach((deviceType) =>{
                categoryOptions.push({"label":deviceType,"value":deviceType})
            })
        })
        .catch((err) => {
          console.log(err)
        });

              
    }, [])

    useEffect(() => {
        
        //enable control divs if deviceInput isn't empty
        if(deviceInput.length){
            console.log("deviceInput:", deviceInput)
            setDisableControl(false)
        }
        //and disable if empty
        else
            setDisableControl(true)
              
    }, [deviceInput])

    useEffect(() => {
        
        setDeviceOptions([])
        deviceInput = []
        var tempDeviceOptions = []
        if(categoryInput != undefined && categoryInput != null){
                        
            apiObject[categoryInput.value].forEach((device) => {
                tempDeviceOptions.push({"label":device,"value":device})
                
            })
            disableDeviceField = false
        }

        setDeviceOptions(tempDeviceOptions)

    }, [categoryInput])


    const handleDeviceInput = (obj) => {
        deviceInput = obj
        setSelectedDevices(deviceInput.map(a=>a.value))
        
    }

    const handleCategoryInput = (obj) => {
        setCategoryInput(obj)
        console.log("categoryInput",categoryInput)
        
    }

    //styles for selected options

    const customStyles = {
        // For the select it self, not the options of the select
        control: (styles, { isDisabled}) => {
          return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            pointerEvents: 'all',
            backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.2)' : 'white',
            color: isDisabled ? 'white' : 'white'
          }
        },
        // For the options
        option: (styles, { isDisabled}) => {
          
          return {
            ...styles,
            cursor: isDisabled ? 'not-allowed' : 'default',
            color:'black'
          };
        },
      };
      

    return(
        <div style={{display: 'flex',  justifyContent:'center', marginTop:"6px", color:"black"}}>
            
            <div style={{width:"40%", marginRight:"5%"}}>
                <br/>
                <div style={{color:"#C0C0C0"}}>
                    Before you can communicate with an FE product, please help us identify the correct device(s). 
                </div>
                <br/>
                <div style={{color:"#C0C0C0"}}>
                    <b>First,</b> please provide the product line that the target device(s) belongs to:  
                </div>
                <br/>               
                <Select
                    styles = {customStyles}
                    maxMenuHeight={220}
                    margin = "normal"
                    closeMenuOnSelect = {false}
                    options={categoryOptions}  
                    placeholder = "Device type"
                    value={categoryInput}
                    onChange={(e) => handleCategoryInput(e)}
                />
            </div>

            
            <div style={{width:"40%", marginLeft:"5%"}}>
                <br/>
                <div style={{color:"#C0C0C0"}}>
                    <b>Next,</b> select the device(s) you would like to control.
                </div>
                <br/>
                <div style={{color:"#C0C0C0"}}>
                    If you do not see your device listed here, you may not have been granted access to it. You can request permission by clicking <a style ={{color:"#1ED760"}} href="https://www.freecodecamp.org/news/how-to-use-html-to-open-link-in-new-tab/" target="_blank" rel="noopener noreferrer">here</a>. 
                </div>
                <br/>
                <Select
                    styles = {customStyles}
                    isDisabled={disableDeviceField}
                    maxMenuHeight={220}
                    margin = "normal"
                    closeMenuOnSelect = {false}
                    isMulti
                    options={deviceOptions}  
                    placeholder = "Device name"
                    value={deviceInput}
                    onChange={(e) => handleDeviceInput(e)}
                />
            </div>
        </div>
        
    )
}