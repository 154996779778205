import Box from '@mui/material/Box';
import { lightBlue } from '@mui/material/colors';
import { useEffect, useState, useCallback} from 'react';

import Select from 'react-select'

//Arrays to store react-select options
var deviceOptions = []

var deviceInput = []

var deviceProcessedInput = []

export default function UserDevices ({setDeviceList}) {

    //Populate react-select with options to select from (data fetched from Inventory).
    useEffect(() => {
        
        fetch("/api/inventory/list-all") /// ENV
        .then(resp =>{
          if(resp.status === 200) return resp.json(); 
        })
        .then(data => {

            data.forEach(function (item, index) {
                deviceOptions.push({"label":item,"value":item})
              });
            console.log(deviceOptions)
        })
        .catch((err) => {
          console.log(err)
        });
      
    }, [])

    //handle user-input
     const handleChange = (obj, id) => {
        
        deviceInput = obj
        deviceProcessedInput = deviceInput.map(a => a.value);
        setDeviceList(deviceProcessedInput)
      };

    //styles for selected options
    const styles = {
        width:100,
        multiValue: styles=>{
            return{
                ...styles,
                backgroundColor:"lightgray"
            }
        }
    }

    

    return (
      <div style={{width: 900, }}>
        <Box component="form" noValidate sx={{ mt: 1, mr:50, ml:2 }}>
        <br/>
        
            <h2 style={{color:"white"}}>Select devices to assign to user here:</h2>
            <br/>
                <div style={{margin: 10}}>
                    <Select
                        styles = {styles}
                        margin = "normal"
                        closeMenuOnSelect = {false}
                        isMulti
                        options={deviceOptions}
                        placeholder = "Type here to search by device name"

                        value={deviceInput}
                        onChange={(option) => handleChange(option, "SDU")}
                    />
                </div>                
        <br/>
        </Box>
      </div>
      
    );
  };
  
  