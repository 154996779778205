import CrashReportList from "../../components/crashReportList";
import { useState } from "react";
import { styled } from "@mui/styles";
import Button from "@mui/material/Button";
import pika from "../../assets/pika.gif";

const CustomButton = styled(Button)({
  width: "81.10%",
  padding: "15px",
  marginLeft: "10%",
  marginRight: "0px",
  marginTop: "15px",
  marginBottom: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "50px",
  boxSizing: "border-box",
  borderRadius: 10,
  background: "#0093f2",
  color: "#ffffff",
  fontWeight: "Condensed Heavy",
  fontSize: "100%",
  transform: "none",
  boxShadow: "none",
  transition: "background 1.0s,border-color .2s,color 0.2s",
  "&:hover": {
    backgroundColor: "green",
  },
});

export default function FEConnectCrashLogs() {
  const [fileSelection, setFileSelection] = useState([]);

  const handleDeployClick = () => {
    alert("Button clicked");
  };

  // Inline-Styles for divs
  // Banner styles
  const mainBannerDiv = {
    width: "99%",
    minWidth: "99%",
    marginLeft: "10px",
    marginRight: "10px",
    height: "40px",
    background: "#224663",
    border: "0.1px solid #1ED760",
    borderRadius: "15px",
    marginTop: "3px",
    textAlign: "center",
    lineHeight: "2.5",
    color: "white",
    fontSize: "16px",
  };

  const subBanner = {
    border: "0.3px solid white",
    borderRadius: "15px",
    color: "white",
    fontSize: "16px",
    margin: "3px",
  };

  var dialContainerDiv = {
    height: "auto",
    width: "80%",
    minWidth: "210px",
    background: "linear-gradient(to top,#224663, #353839)",
    border: "0.5px solid #005DAA",
    borderRadius: "15px",
    marginTop: "5px",
    marginBottom: "10px",
    marginLeft: "1%",
    marginRight: "0.10%",
    textAlign: "center",
    color: "black",
    fontSize: "16px",
    padding: "5px",
    overflow: "auto",
  };

  return (
    <div
      style={{
        marginLeft: "30px",
        marginRight: "30px",
        marginBottom: "30px",
      }}
    >
      <div style={mainBannerDiv}>FE Connect Crash Reports</div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {
          // Panel for IRIS devices
        }
        <div style={dialContainerDiv}>
          <CrashReportList setIrisSelection={setFileSelection} />
        </div>
      </div>
      <div style={{ marginLeft: "40%", display: "inline-flex" }}>
        <img src={pika} alt="loading..." />
      </div>
    </div>
  );
}
